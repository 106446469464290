import React, { useState, useEffect } from 'react';

const Switcher = ({ values, callback, align, width }) => {
  const [active, setActive] = useState(false);

  const styles = {
    align: align || 'justify-center',
    width: width || 'w-full sm:w-auto',
  };

  useEffect(() => {
    active ? callback(true) : callback(false)
  }, [active]);

  return (
    <div
      className={`flex items-center ${styles.align} ${width} gap-4 sm:gap-7 p-5 md:p-7 bg-black-200 rounded`}>
      <p className=" text-xs sm:text-sm md:text-[18px]">{values[0]}</p>
      <div
        className={`cursor-pointer relative flex bg-black-300 h-9 min-w-[62px] p-1  rounded-[46px] transition-all duration-500`}
        onClick={() => setActive(!active)}>
        <div
          className={`absolute top-1/2 transform -translate-y-1/2 ${
            active ? ' translate-x-[calc(100%-2px)] ' : ' left-1'
          } flex-0-0 rounded-full bg-blue h-[28px] w-[28px] transition-all duration-500`}></div>
      </div>
      <p className=" text-xs sm:text-sm md:text-[18px]">{values[1]}</p>
    </div>
  );

  return (
    <div className=" flex items-center justify-between w-full sm:w-auto gap-4 sm:gap-7 p-5  md:p-7 bg-black-200 rounded">
      <p className=" text-xs s:text-sm md:text-[18px]">{values[0]}</p>
      <div
        className={`cursor-pointer relative flex ${
          !active ? 'justify-start' : 'justify-end'
        }  bg-black-300 h-9 w-[77px] p-1 rounded-[46px] `}
        onClick={() => {
          setActive(!active);
        }}>
        <div className=" rounded-full bg-blue h-full w-[28px] "></div>
      </div>
      <p className="text-xs s:text-sm md:text-[18px]">{values[1]}</p>
    </div>
  );
};

export default Switcher;
