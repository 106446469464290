import React from 'react';

const Card = ({ icon, desc }) => {
  return (
    <div className="col-span-2 sm:col-span-1 relative  aspect-[5/3] sm:aspect-[8/7.2] lg:aspect-[8/4] xxl:aspect-[3/1] noselect group h-auto ">
      <div className="card card--trading flex flex-col justify-between    p-5 sm:p-7 lg:p-10   ">
        <div className="absolute   right-[-40.5%] sm:right-[-38.6%] lg:right-[-38.9%] bottom-[6px] w-full rotate-[143deg] sm:rotate-[132deg] lg:rotate-[148deg] xxl:rotate-[157deg]  border-[1px] border-blue"></div>
        <div className="flex flex-col justify-between  sm:justify-start md:justify-between h-full ">
          <div className="h-1/2 sm:h-1/3"> {icon}</div>

          <p className=" h-1/2 text-base sm:text-[20px] lg:text-[26px] xxl:text-[30px] uppercase font-bold">
            {desc}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Card;
