import React, { useEffect, useState } from 'react';
import markdownToHtml from '../../../helpers/markdownToHtml';

const Item = ({ quest, answer }) => {
  const [active, setActive] = useState(false);
  const [formattedQuestion, setFormattedQuestion] = useState('');
  const [formattedAnswer, setFormattedAnswer] = useState('');

  useEffect(() => {
    handleText();
  }, []);

  const handleText = async () => {
    setFormattedQuestion(await markdownToHtml(quest));
    setFormattedAnswer(await markdownToHtml(answer));
  };

  return (
    <div
      className="relative  p-[20px] sm:p-7 sm:pr-10 rounded bg-black-400 cursor-pointer"
      onClick={(e) => {
        setActive(!active);
        e.stopPropagation();
      }}>
      <p
        className="markdown-block text-xs sm:text-base lg:text-sm xxl:text-base"
        dangerouslySetInnerHTML={{ __html: formattedQuestion }}
      />

      <div
        className={`${
          active ? 'mt-5' : 'h-0 overflow-hidden'
        } markdown-block font-cera text-xs sm:text-base lg:text-sm xxl:text-base transition-all`}
        dangerouslySetInnerHTML={{ __html: formattedAnswer }}
      />
      <div className=" absolute top-[14px] right-[30px] sm:top-[24px] sm:right-[30px]">
        {active ? '-' : '+'}
      </div>
    </div>
  );
};

export default Item;
