import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import SvgSprite from '../../ui/SvgSprite';
import { useTranslation } from 'react-i18next';

const Nav = () => {
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;
  const [showDropDown, setShowDropDown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setShowDropDown(false);
  }, [currentLanguage]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropDown(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
  };
  return (
    <>
      <nav className="hidden lg:flex  gap-4 xl:gap-14  xl:ml-auto xl:mr-[60px] ">
        <ul className="flex  gap-12 self-center ">
          <li className="hover:text-grey duration-200">
            <Link to={`https://1ex.com/`}>{t('nav.first')}</Link>
          </li>
          <li className="hover:text-grey duration-200">
            <HashLink smooth to={`/#trading`}>
              {t('nav.second')}
            </HashLink>
          </li>
          <li className="hover:text-grey duration-200">
            <HashLink smooth to={`/#education`}>
              {t('nav.third')}
            </HashLink>
          </li>
          <li className="hover:text-grey duration-200">
            <HashLink smooth to={`/#pricing`}>
              {t('nav.fourth')}
            </HashLink>
          </li>
          <li className="hover:text-grey duration-200">
            <HashLink smooth to={`/#about`}>
              {t('nav.fifth')}
            </HashLink>
          </li>
        </ul>
      </nav>
      <div className="relative hidden lg:flex gap-3 self-center">
        <Link to={`https://account.1ex.com/ru/auth/signup`}>
          <button className="flex items-center justify-center  text-black font-bold uppercase rounded py-3 px-4 bg-blue duration-200 hover:bg-green">
            {t('header.buttons.sign_in')}
          </button>
        </Link>

        {currentLanguage == 'en' ? (
          <>
            <button
              ref={dropdownRef}
              className="flex items-center justify-center  text-white font-bold uppercase rounded py-3 px-4 bg-black-300"
              onClick={() => setShowDropDown(!showDropDown)}>
              <div className="mr-[3px]">
                <SvgSprite src={`/img/svg/languages/en.svg`} width={16} height={12} />
              </div>
              {t('header.buttons.lang')}
            </button>
            <button
              ref={dropdownRef}
              className={`z-[-1]  ${
                showDropDown ? 'flex' : 'hidden'
              }  absolute right-0 top-[110%]  items-center justify-center  text-white font-bold uppercase rounded py-3 px-4 bg-black-300 animate-fadeTopBottom`}
              onClick={() => changeLanguage('ru')}>
              <div className="mr-[3px]">
                <SvgSprite src={`/img/svg/languages/ru.svg`} width={16} height={12} />
              </div>
              {t('header.buttons.lang_2')}
            </button>
          </>
        ) : (
          <>
            <button
              ref={dropdownRef}
              className="flex items-center justify-center  text-white font-bold uppercase rounded py-3 px-4 bg-black-300"
              onClick={() => setShowDropDown(!showDropDown)}>
              <div className="mr-[3px]">
                <SvgSprite src={`/img/svg/languages/ru.svg`} width={16} height={12} />
              </div>
              {t('header.buttons.lang')}
            </button>
            <button
              ref={dropdownRef}
              className={`z-[-1]  ${
                showDropDown ? 'flex' : 'hidden'
              }  absolute right-0 top-[110%]  items-center justify-center  text-white font-bold uppercase rounded py-3 px-4 bg-black-300 animate-fadeTopBottom`}
              onClick={() => changeLanguage('en')}>
              <div className="mr-[3px]">
                <SvgSprite src={`/img/svg/languages/en.svg`} width={16} height={12} />
              </div>
              {t('header.buttons.lang_2')}
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default Nav;
