import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Question from './Question';
import useGetFaqList from '../../../hooks/fetch/faq/useGetFaqList';

const Index = () => {
  const { t, i18n } = useTranslation();
  const { faqList, loading, error } = useGetFaqList();
  const [data, setData] = useState([]);
  const currentLanguage = i18n.language;

  useEffect(() => {
    if (!loading) {
      setData(faqList.data);
    }
  }, [loading, faqList]);

  return (
    <section className="relative py-[100px] lg:py-[140px] min-h-screen xs:h-auto bg-snippet bg-offset-x-60  bg-[url('/public/img/hero_bg_gradient.webp')]">
      {/* <div className="z-[-1] pointer-events-none absolute top-0 left-0 w-full h-full bg-snippet  bg-net opacity-10 bg-[url('/public/img/bg_net.svg')]"></div> */}
      <div
        className={`z-[1] absolute hidden scale-x-1 sm:block sm:top-[4%] sm:right-0  sm:w-[40%] sm:scale-x-[-1] ${
          currentLanguage == 'en' ? 'x:top-[400px]' : 'x:top-[600px]'
        } x:left-0 x:scale-x-100`}>
        <img src="/img/pages/faq/plane.png" alt="faq" />
      </div>
      <div className="container flex  justify-between x:flex-row xs:flex-col">
        <div className={`z-10 flex flex-col w-full  x:w-[30%]`}>
          <h1 className={`${currentLanguage == 'en' ? '' : 'sm:w-2/3'}`}>{t('faq.title')}</h1>
          <p className=" uppercase mt-5 sm:mt-10  xl:mt-[60px] text-base sm:text-lg lg:text-[26px] xl:text-[30px]  ">
            {t('faq.description')}
          </p>
        </div>
        <div className="z-[2] flex flex-col  gap-[10px] sm:gap-5  x:w-[55%]  mt-[40px] sm:mt-[60px] lg:mt-0">
          {!loading &&
            data &&
            data.length &&
            data.map((item) => <Question key={item.id} id={item.id} {...item.attributes} />)}
        </div>
      </div>
    </section>
  );
};

export default Index;
