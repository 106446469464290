import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../ui/Button';
import SvgSprite from '../../ui/SvgSprite';
import MailForm from './MailForm';
import getSocial from '../../../data/social';

import SocialCard from './SocialCard';

const Index = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const socials = getSocial(
    [
      t('footer.social.first.title'),
      t('footer.social.second.title'),
      t('footer.social.third.title'),
      t('footer.social.fourth.title'),
      t('footer.social.fifth.title'),
      t('footer.social.sixth.title'),
    ],
    false,
    25,
  );

  const [socialsNew, setSocialsNew] = useState([]);

  useEffect(() => {
    formatSocials();
  }, [currentLanguage]);

  useEffect(() => {
    formatSocials();
  }, []);
  const formatSocials = () => {
    let arr = [];
    socials.forEach((item) => {
      switch (item.name.toLowerCase()) {
        case t('footer.social.first.title').toLowerCase():
          arr[0] = {
            ...item,
            desc: t('footer.social.first.description'),
            merge: 'col-span-2',
            titleTransform: '',
          };
          break;
        case t('footer.social.second.title').toLowerCase():
          arr[1] = {
            ...item,
            desc: t('footer.social.second.description'),
            merge: 'col-span-3',
            titleTransform: '',
          };
          break;
        case t('footer.social.third.title').toLowerCase():
          arr[2] = {
            ...item,
            desc: t('footer.social.third.description'),
            merge: 'col-span-3',
            titleTransform: '',
          };
          break;
        case t('footer.social.fourth.title').toLowerCase():
          arr[3] = {
            ...item,
            desc: t('footer.social.fourth.description'),
            merge: 'col-span-2',
            titleTransform: '',
          };
          break;
        case t('footer.social.fifth.title').toLowerCase():
          arr[4] = {
            ...item,
            desc: t('footer.social.fifth.description'),
            merge: 'col-span-2',
            titleTransform: '',
          };
          break;
        case t('footer.social.sixth.title').toLowerCase():
          arr[5] = {
            ...item,
            desc: t('footer.social.sixth.description'),
            merge: 'col-span-3',
            titleTransform: '',
          };
          break;

        default:
          break;
      }
    });

    setSocialsNew(arr);
  };
  // Пока нет ссылки на robots
  return (
    <footer className="relative min-h-[723px]  xs:min-h-auto py-[100px]">
      {/* <div className="z-[-1] pointer-events-none absolute top-0 left-0 w-full h-full bg-snippet bg-net opacity-10 bg-[url('/public/img/bg_net.svg')]"></div> */}
      <div className="container flex flex-col">
        <div className="grid x:grid-cols-3 x:grid-rows-1 x:gap-20 md:grid md:grid-cols-2 xs:grid-rows-2 gap-5">
          <a
            href={t('footer.buttons.firstLink')}
            target='_blank'
            download
            className="flex-1">
            <Button
              value={t('footer.buttons.first')}
              align={`justify-between`}
              width={`w-full`}
              icon={<SvgSprite src="/img/svg/icons/download.svg" width={14} height={14} />}
            />
          </a>

          <a
            href={t('footer.buttons.thirdLink')}
            target='_blank'
            download
            className="flex-1">
            <Button
              value={t('footer.buttons.third')}
              align={`justify-between`}
              width={`w-full`}
              icon={<SvgSprite src="/img/svg/icons/download.svg" width={14} height={14} />}
            />
          </a>

          <a
            href={t('footer.buttons.fourthLink')}
            target='_blank'
            download
            className="flex-1 md:col-span-2 x:col-span-1">
            <Button
              value={t('footer.buttons.fourth')}
              align={`justify-between`}
              width={`w-full`}
              icon={<SvgSprite src="/img/svg/icons/download.svg" width={14} height={14} />}
            />
          </a>
        </div>
        <div className="flex mt-14 justify-between gap-[5%]  x:flex-row flex-col">
          <div className="flex flex-col w-full lg:w-[52%] xxl:w-1/2   ">
            <div className="grid   gap-5 grid-cols-3 grid-rows-2 md:grid-cols-5 md:grid-rows-3 perspective-1000">
              {socialsNew &&
                socialsNew.length &&
                socialsNew.map((item) => <SocialCard key={item.desc + item.title} {...item} />)}
            </div>
          </div>
          <div className="flex flex-col w-full mt-12  lg:w-[40%] x:mt-0 xxl:w-[40%]">
            <MailForm />
          </div>
        </div>
      </div>
    </footer>
  );

  return (
    <footer className="relative min-h-[723px]  xs:min-h-auto py-[100px]">
      {/* <div className="z-[-1] pointer-events-none absolute top-0 left-0 w-full h-full bg-snippet bg-net opacity-10 bg-[url('/public/img/bg_net.svg')]"></div> */}
      <div className="container flex flex-col">
        <div className="grid x:grid-cols-4 x:grid-rows-1 x:gap-20 md:grid md:grid-cols-2 xs:grid-rows-2 gap-5">
          <a
            href={`/files/manuals_1Ex/Promo_Guide_1ExAlg_${currentLanguage}.chm`}
            download
            className="flex-1">
            <Button
              value={`Promo docs`}
              align={`justify-between`}
              width={`w-full`}
              icon={<SvgSprite src="/img/svg/icons/download.svg" width={14} height={14} />}
            />
          </a>

          <Button
            value={`Robot docs`}
            align={`justify-between`}
            width={`flex-1`}
            icon={<SvgSprite src="/img/svg/icons/download.svg" width={14} height={14} />}
          />
          <a
            href={`/files/manuals_1Ex/User's_Guide_1ExAlgo_${currentLanguage}.pdf`}
            download
            className="flex-1">
            <Button
              value={`General docs`}
              align={`justify-between`}
              width={`w-full`}
              icon={<SvgSprite src="/img/svg/icons/download.svg" width={14} height={14} />}
            />
          </a>

          <a
            href={`/files/manuals_1Ex/API_1ExAlgo_${currentLanguage}.pdf`}
            download
            className="flex-1">
            <Button
              value={`API docs`}
              align={`justify-between`}
              width={`w-full`}
              icon={<SvgSprite src="/img/svg/icons/download.svg" width={14} height={14} />}
            />
          </a>
        </div>
        <div className="flex mt-14 justify-between gap-[5%]  x:flex-row flex-col">
          <div className="flex flex-col w-full x:w-4/5 xxl:w-1/2   ">
            <div className="grid   gap-5 grid-cols-3 grid-rows-2 md:grid-cols-5 md:grid-rows-3 perspective-1000">
              {socialsNew &&
                socialsNew.length &&
                socialsNew.map((item) => <SocialCard key={item.desc + item.title} {...item} />)}
            </div>
          </div>
          <div className="flex flex-col w-full mt-12  x:w-2/5 x:mt-0 xxl:w-[40%]">
            <MailForm />
          </div>
        </div>
      </div>
    </footer>
  );
  return (
    <footer className="relative min-h-[723px]  xs:min-h-auto py-[100px]">
      <div className="z-[-1] pointer-events-none absolute top-0 left-0 w-full h-full bg-snippet bg-net opacity-10 bg-[url('/public/img/bg_net.svg')]"></div>
      <div className="container flex flex-col">
        <div className="grid x:grid-cols-4 x:grid-rows-1 x:gap-20 md:grid md:grid-cols-2 xs:grid-rows-2 gap-5">
          <a
            href={`/files/manuals_1Ex/Promo_Guide_1ExAlg_${currentLanguage}.chm`}
            download
            className="flex-1">
            <Button
              value={`Promo docs`}
              align={`justify-between`}
              width={`w-full`}
              icon={<SvgSprite src="/img/svg/icons/download.svg" width={14} height={14} />}
            />
          </a>

          <Button
            value={`Robot docs`}
            align={`justify-between`}
            width={`flex-1`}
            icon={<SvgSprite src="/img/svg/icons/download.svg" width={14} height={14} />}
          />
          <a
            href={`/files/manuals_1Ex/User's_Guide_1ExAlgo_${currentLanguage}.pdf`}
            download
            className="flex-1">
            <Button
              value={`General docs`}
              align={`justify-between`}
              width={`w-full`}
              icon={<SvgSprite src="/img/svg/icons/download.svg" width={14} height={14} />}
            />
          </a>

          <a
            href={`/files/manuals_1Ex/API_1ExAlgo_${currentLanguage}.pdf`}
            download
            className="flex-1">
            <Button
              value={`API docs`}
              align={`justify-between`}
              width={`w-full`}
              icon={<SvgSprite src="/img/svg/icons/download.svg" width={14} height={14} />}
            />
          </a>
        </div>
        <div className="flex mt-14 justify-between gap-[5%]  x:flex-row flex-col">
          <div className="flex flex-col w-full x:w-3/4 xxl:w-1/2   ">
            <div className="grid   gap-5 grid-cols-3 grid-rows-2 md:grid-cols-5 md:grid-rows-3">
              <div className=" p-[30px] col-auto  md:col-span-2  border-2 border-blue  rounded-[20px] no-select  ">
                <p className="flex items-center justify-center md:justify-normal font-bold uppercase text-2xl gradient-text gradient-text--blue">
                  <span className="hidden md:block">MEDIUM</span>
                  <span className=" ml-0  md:ml-4">
                    <SvgSprite src="/img/svg/social/medium.svg" width={20} height={20} />
                  </span>
                </p>
                <p className=" mt-5 hidden md:block ">Review the latest updates</p>
              </div>
              <div className=" p-[30px] col-auto md:col-span-3  border-2 border-blue  rounded-[20px] no-select  ">
                <p className="flex items-center justify-center md:justify-normal font-bold uppercase text-2xl gradient-text gradient-text--blue">
                  <span className="hidden md:block">Telegram</span>
                  <span className=" ml-0  md:ml-4">
                    <SvgSprite src="/img/svg/social/telegram.svg" width={20} height={20} />
                  </span>
                </p>
                <p className=" mt-5 hidden md:block">
                  Join the conversation! Interact with other 1ex traders in Telegram
                </p>
              </div>
              <div className=" p-[30px] col-auto md:col-span-3  border-2 border-blue  rounded-[20px] no-select  ">
                <p className="flex items-center justify-center md:justify-normal font-bold uppercase text-2xl gradient-text gradient-text--blue">
                  <span className="hidden md:block">Youtube</span>
                  <span className=" ml-0  md:ml-4 ">
                    <SvgSprite src="/img/svg/social/youtube.svg" width={20} height={20} />
                  </span>
                </p>
                <p className=" mt-5 hidden md:block">
                  Watch educational videos from our team and other interesting trading content
                </p>
              </div>

              <div className=" p-[30px] col-auto md:col-span-2 border-2 border-blue  rounded-[20px] no-select  ">
                <p className="flex items-center justify-center md:justify-normal font-bold  text-2xl gradient-text gradient-text--blue">
                  <span className="hidden md:block">LinkedIN</span>
                  <span className=" ml-0  md:ml-4">
                    <SvgSprite src="/img/svg/social/linkedin.svg" width={20} height={20} />
                  </span>
                </p>
                <p className=" mt-5 hidden md:block">
                  Let’s be friend! Add us on LinkedIn for any business inquiries
                </p>
              </div>
              <div className=" p-[30px] col-auto md:col-span-2 border-2 border-blue  rounded-[20px] no-select  ">
                <p className="flex items-center justify-center md:justify-normal font-bold  text-2xl gradient-text gradient-text--blue">
                  <span className="hidden md:block">Twitter</span>
                  <span className=" ml-0  md:ml-4">
                    <SvgSprite src="/img/svg/social/twitter.svg" width={20} height={20} />
                  </span>
                </p>
                <p className=" mt-5 hidden md:block">
                  Follow us on Twitter for the latest product updates & company news.
                </p>
              </div>
              <div className=" p-[30px] col-auto md:col-span-3  border-2 border-blue  rounded-[20px] no-select  ">
                <p className="flex items-center justify-center md:justify-normal font-bold  text-2xl gradient-text gradient-text--blue">
                  <span className="hidden md:block">INSTAGRAM</span>
                  <span className=" ml-0  md:ml-4">
                    <SvgSprite src="/img/svg/social/instagram.svg" width={20} height={20} />
                  </span>
                </p>
                <p className=" mt-5 hidden md:block">
                  Check fast graphic trading content at your favorite social media
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full mt-12  x:w-1/4 x:mt-0 xxl:w-[40%]">
            <MailForm />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Index;
