import React from 'react';

const Carousel = () => {
  return (
    <div className="marquee-infinite bg-black-300 w-full overflow-hidden ">
      <div className="ticker-box animate-pause">
        <div className="w-full flex justify-center items-center  gap-12 md:gap-20   ">
          <div className="relative w-[160px] h-[50px] sm:w-[160px] sm:h-[70px] md:w-max md:h-[100px] flex items-center">
            <img src="/img/partners/partner_binance.webp" alt="binance" />
          </div>
          <div className="relative w-[160px] h-[50px] sm:w-[160px] sm:h-[70px] md:w-max md:h-[100px] flex items-center">
            <img src="/img/partners/partner_bitfinex.webp" alt="bitfinex" />
          </div>
          <div className="relative w-[160px] h-[50px] sm:w-[160px] sm:h-[70px] md:w-max md:h-[100px] flex items-center">
            <img src="/img/partners/partner_bybit.webp" alt="bybit" />
          </div>
          <div className="relative w-[160px] h-[50px] sm:w-[160px] sm:h-[70px] md:w-max md:h-[100px] flex items-center">
            <img src="/img/partners/partner_deribit.webp" alt="derebit" />
          </div>
          <div className="relative w-[160px] h-[50px] sm:w-[160px] sm:h-[70px] md:w-max md:h-[100px] flex items-center">
            <img src="/img/partners/partner_gate.webp" alt="gate" />
          </div>
          <div className="relative w-[160px] h-[50px] sm:w-[160px] sm:h-[70px] md:w-max md:h-[100px] flex items-center">
            <img src="/img/partners/partner_huobi.webp" alt="huobi" />
          </div>
          <div className="relative w-[160px] h-[50px] sm:w-[160px] sm:h-[70px] md:w-max md:h-[100px] flex items-center">
            <img src="/img/partners/partner_kucoin.webp" alt="kucoin" />
          </div>
          <div className="relative w-[160px] h-[50px] sm:w-[160px] sm:h-[70px] md:w-max md:h-[100px] flex items-center mr-12 md:mr-20">
            <img src="/img/partners/partner_okx.webp" alt="okx" />
          </div>
        </div>
        <div className="w-full flex justify-center items-center gap-12 md:gap-20 ">
          <div className="relative w-[160px] h-[70px] md:w-max md:h-[100px] flex items-center">
            <img src="/img/partners/partner_binance.webp" alt="binance" />
          </div>
          <div className="relative w-[160px] h-[70px] md:w-max md:h-[100px] flex items-center">
            <img src="/img/partners/partner_bitfinex.webp" alt="bitfinex" />
          </div>
          <div className="relative w-[160px] h-[70px] md:w-max md:h-[100px] flex items-center">
            <img src="/img/partners/partner_bybit.webp" alt="bybit" />
          </div>
          <div className="relative w-[160px] h-[70px] md:w-max md:h-[100px] flex items-center">
            <img src="/img/partners/partner_deribit.webp" alt="derebit" />
          </div>
          <div className="relative w-[160px] h-[70px] md:w-max md:h-[100px] flex items-center">
            <img src="/img/partners/partner_gate.webp" alt="gate" />
          </div>
          <div className="relative w-[160px] h-[70px] md:w-max md:h-[100px] flex items-center">
            <img src="/img/partners/partner_huobi.webp" alt="huobi" />
          </div>
          <div className="relative w-[160px] h-[70px] md:w-max md:h-[100px] flex items-center">
            <img src="/img/partners/partner_kucoin.webp" alt="kucoin" />
          </div>
          <div className="relative w-[160px] h-[70px] md:w-max md:h-[100px] flex items-center mr-12 md:mr-20">
            <img src="/img/partners/partner_okx.webp" alt="okx" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
