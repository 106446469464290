import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Education from '../components/education';

const EducationPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Education</title>
        <meta
          name="description"
          content="Empower yourself with knowledge through our Education Page. Access resources, tutorials, and courses that cover a wide range of topics in algorithmic trading. Learn and grow with us."
        />
        <meta property="og:title" content="Education" />
        <meta
          property="og:description"
          content="Empower yourself with knowledge through our Education Page. Access resources, tutorials, and courses that cover a wide range of topics in algorithmic trading. Learn and grow with us."
        />
        <meta property="og:url" content={`${process.env.REACT_APP_DOMAIN}/education`} />
      </Helmet>
      <Education />
    </>
  );
};

export default EducationPage;
