import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../components/trading/Hero';
import Cards from '../components/trading/Cards';
import List from '../components/trading/List';

const Trading = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Trading bots</title>
        <meta
          name="description"
          content="Explore our state-of-the-art Trading Bots designed to maximize your profits and minimize risks. Take advantage of automation for a smarter and more efficient trading strategy."
        />
        <meta property="og:title" content="Trading bots" />
        <meta
          property="og:description"
          content="Explore our state-of-the-art Trading Bots designed to maximize your profits and minimize risks. Take advantage of automation for a smarter and more efficient trading strategy."
        />
        <meta property="og:url" content={`${process.env.REACT_APP_DOMAIN}/trading`} />
      </Helmet>
      <Hero />
      <Cards />
      <List />
    </>
  );
};

export default Trading;
