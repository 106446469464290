import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../ui/Button';
import SvgSprite from '../../ui/SvgSprite';

import Marquee from './Marquee';
import SubMin from '../../ui/Subscription/SubMin';
import { Link } from 'react-router-dom';

const Hero = () => {
  const { t } = useTranslation();
  return (
    <section className="relative  overflow-x-hidden  overflow-y-hidden lg:max-h-[730px] lg:h-screen xxl:max-h-[920px]  bg-snippet    bg-offset-custom-hero-main bg-[url('/public/img/hero_bg_gradient.webp')]">
      {/* <div className="z-[-1] pointer-events-none absolute top-0 left-0 w-full h-full bg-snippet bg-net opacity-10 bg-[url('/public/img/bg_net.svg')]"></div> */}
      <div className="z-index-[-1] absolute left-0 bottom-[15%] w-full justify-end hidden lg:block">
        <Marquee />
      </div>
      <div className="flex flex-col h-full pt-[100px] lg:pt-0">
        <div className="container flex  h-full flex-col">
          <div className="flex flex-col lg:flex-row justify-between h-full lg:pt-[10%] xxl:pt-[10%]">
            <div className="flex flex-col  w-full lg:w-[50%]  ">
              <h1 className="uppercase font-bold mb-10  ">{t('home.hero.title')}</h1>
              <div className="z-[1] ">
                <Link to={t('home.hero.downloadLink')} target="_blank" rel="noopener noreferrer">
                  <Button
                    value={t('home.hero.button')}
                    width={`w-full sm:w-1/2 lg:w-auto`}
                    textSize={'text-base lg:text-lg'}
                    icon={<SvgSprite src="/img/svg/icons/arrow.svg" width={27} height={20} />}
                  />
                </Link>
              </div>
            </div>
            <div className="relative flex-col flex justify-between   h-auto gap-10 lg:h-full w-full lg:w-[42%] xl:w-[42%]  mt-[50px] lg:mt-0">
              <div className=" flex justify-between  gap-2  lg:gap-8  ">
                <div className="flex-col gap-5 w-3/4 sm:w-[30%] lg:w-[33.33%]">
                  <p className="text-green uppercase font-bold whitespace-nowrap  text-[20px] sm:text-4xl  xxl:text-[50px]     tracking-tight">
                    {t('home.hero.features.first.title')}
                  </p>
                  <p className="text-white w-3/4 text-xs sm:text-base  mt-[10px] lg:mt-5">
                    {t('home.hero.features.first.description')}
                  </p>
                </div>
                <div className="flex-col gap-5 w-3/4 sm:w-[30%] lg:w-[33.33%]">
                  <p className="text-green uppercase font-bold whitespace-nowrap text-[20px] sm:text-4xl xxl:text-[50px]  tracking-tight">
                    {t('home.hero.features.second.title')}
                  </p>
                  <p className="text-white   text-xs sm:text-base mt-[10px] lg:mt-5">
                    {t('home.hero.features.second.description')}
                  </p>
                </div>
                <div className="flex-col gap-5 w-3/4 sm:w-[34%] lg:w-[33.33%]">
                  <p className="text-green uppercase font-bold whitespace-nowrap text-[20px] sm:text-4xl xxl:text-[50px]  tracking-tight">
                    {t('home.hero.features.third.title')}
                  </p>
                  <p className="text-white w-3/4 text-xs sm:text-base mt-[10px] lg:mt-5">
                    {t('home.hero.features.third.description')}
                  </p>
                </div>
              </div>
              <div className=" z-20  w-full self-center sm:w-3/4 lg:w-full translate-y-[6px] lg:scale-[1.1] lg:translate-y-[-12px]  xxl:translate-y-[-33px]  xxl:scale-[1.2]">
                <img
                  src="/img/hero_macbook.webp"
                  alt="macbook-trading"
                  loading="lazy"
                  width={`100%`}
                />
              </div>
            </div>
          </div>
        </div>
        <SubMin
          title={t('home.hero.subscribe.title')}
          placeholder={t('home.hero.subscribe.placeholder')}
        />
      </div>
    </section>
  );
};

export default Hero;
