import React from "react";
import { Link } from "react-router-dom";
import Button from "../../ui/Button";
import SvgSprite from "../../ui/SvgSprite";
import Video from "./Video";

import { useTranslation } from "react-i18next";

const Index = () => {
    const { t, i18n } = useTranslation();

    const currentLanguage = i18n.language;
    const videos = t("trading.videoId", { returnObjects: true });

    return (
        <section className='relative '>
            {/* <div className="z-[-1] pointer-events-none absolute top-0 left-0 w-full h-full bg-snippet bg-net opacity-10 bg-[url('/public/img/bg_net.svg')]"></div> */}
            <div className='container pt-[80px] lg:pt-[120px] xl:pt-[120px]'>
                <div className='relative flex flex-col p-5 lg:p-20 bg-gradient-to-br from-[#35dbe552] to-[#25273C00] rounded'>
                    <div className='absolute w-full h-full top-0 left-0 bg-[#212137] rounded z-[-1]'></div>
                    <div className='flex flex-col lg:flex-row justify-between'>
                        <div
                            className={`flex flex-col justify-between w-full sm:w-[60%]  ${
                                currentLanguage == "en" ? "lg:w-[30%]" : " lg:w-[30%] xl:w-[40%]"
                            } `}
                        >
                            <h1 className=''>{t("trading.title")}</h1>
                            <div className=' pt-8 lg:pt-0'>
                                <Link
                                    to={t("trading.downloadLink")}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    <Button
                                        value={t("trading.button")}
                                        icon={
                                            <SvgSprite
                                                src='/img/svg/icons/arrow.svg'
                                                width={27}
                                                height={20}
                                            />
                                        }
                                        width={`w-full xl:w-[80%]`}
                                        textSize={`text-base `}
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 grid-rows-2  gap-y-[30px] gap-12  w-full lg:w-3/5 mt-10 lg:mt-0'>
                            <div className='col-span-2 sm:col-span-1'>
                                <SvgSprite
                                    src='/img/svg/icons/main-radar.svg'
                                    fill={`none`}
                                    width={26}
                                    height={26}
                                />
                                <p className='w-3/4 sm:w-full uppercase text-base sm:text-[18px] lg:text-lg mt-7'>
                                    {t("trading.features.first.title")}
                                </p>
                            </div>
                            <div className=' col-span-2 sm:col-span-1'>
                                <SvgSprite
                                    src='/img/svg/icons/main-radar.svg'
                                    fill={`none`}
                                    width={26}
                                    height={26}
                                    className='rotate-45'
                                />
                                <p className='w-3/4 sm:w-full uppercase text-base sm:text-[18px] lg:text-lg mt-7'>
                                    {t("trading.features.second.title")}
                                </p>
                            </div>
                            <div className='col-span-2 sm:col-span-1'>
                                <SvgSprite
                                    src='/img/svg/icons/main-radar.svg'
                                    fill={`none`}
                                    width={26}
                                    height={26}
                                    className='rotate-[129deg]'
                                />
                                <p className='w-3/4 sm:w-full uppercase text-base sm:text-[18px] lg:text-lg mt-7'>
                                    {t("trading.features.third.title")}
                                </p>
                            </div>
                            <div className='col-span-2 sm:col-span-1'>
                                <SvgSprite
                                    src='/img/svg/icons/main-radar.svg'
                                    fill={`none`}
                                    width={26}
                                    height={26}
                                    className=' -rotate-90'
                                />
                                <p className='w-3/4 sm:w-full uppercase text-base sm:text-[18px] lg:text-lg mt-7'>
                                    {t("trading.features.fourth.title")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col lg:flex-row justify-between  gap-10 mt-[100px]  '>
                        {videos.map((id, index) => (
                            <div
                                className='flex-1 rounded overflow-hidden  aspect-video'
                                key={index}
                            >
                                <Video id={id} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Index;
