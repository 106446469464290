import React from 'react';

const Button = ({
  value,
  width,
  bgColor,
  textColor,
  textSize,
  borderColor,
  icon,
  align,
  callback,
  disabled,
}) => {
  const styles = {
    align: align || 'justify-center',
    textSize: textSize || 'text-[18px]',
    width: width || '',
    bgColor: bgColor || 'bg-blue',
    textColor: textColor || 'text-black',
    borderColor: borderColor || 'border-blue',
  };
  return (
    <button
      disabled={disabled}
      onClick={callback}
      className={`flex items-center  ${styles.align} ${styles.width}  min-w-min whitespace-nowrap  py-2 px-7 rounded-lg sm:rounded  border ${styles.borderColor}  text-center ${styles.textColor} ${styles.textSize}  font-bold ${styles.bgColor}  duration-200  hover:bg-green hover:border-green hover:text-black  disabled:bg-grey-100 disabled:border-grey-100`}>
      {value} {icon && <div className="ml-2">{icon}</div>}
    </button>
  );
};

export default Button;
