import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import Searcher from './Searcher';
import Button from '../ui/Button';
import SvgSprite from '../ui/SvgSprite';
import Card from './Card';
import SkeletonCard from './Skeleton/SkeletonCard';
import SinglePage from './SinglePage';
import SubMin from '../ui/Subscription/SubMin';
import useGetEducationList from '../../hooks/fetch/education/useGetEducationList';

import useGetRubricList from '../../hooks/fetch/education/useGetRubricList';
import markdownToHtml from '../../helpers/markdownToHtml';

const mockData = Array.from(new Array(26));

const Index = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [activeMenuIndex, setActiveMenuIndex] = useState(null);
  const [activeSlide, setActiveSlide] = useState(false);
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [currentId, setCurrentId] = useState(id);
  const { educationList, loading, error } = useGetEducationList(searchValue, activeMenuIndex);

  const { rubricList, loadingRubric, errorRubric } = useGetRubricList();

  //Change the currentId when the id from url has change
  useEffect(() => {
    if (!id) {
      setActiveSlide(false);
    } else {
      setCurrentId(id);
    }
  }, [id]);

  useEffect(() => {
    if (!loading && !error) {
      setData(educationList.data);
    }
  }, [educationList, loading]);

  //Hide the central block when the menu category changes
  useEffect(() => {
    if (activeMenuIndex) {
      setActiveSlide(false);
      navigate('/education');
    }
  }, [activeMenuIndex]);

  //Reset  category when language has change
  useEffect(() => {
    setActiveMenuIndex(null);
  }, [currentLanguage]);

  //Scroll to top when card is selected
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeSlide]);

  return (
    <section className="relative pt-[90px] lg:pt-[150px] ">
      {/* <div className="z-[-1] pointer-events-none absolute top-0 left-0 w-full h-full bg-snippet bg-net opacity-10 bg-[url('/public/img/bg_net.svg')]"></div> */}
      <div className="container">
        <div className="flex flex-col">
          <div className="flex flex-col lg:flex-row justify-between w-full">
            <h1 className=" w-full lg:w-3/5 xx:w-2/5">1ex {t('education.title')}</h1>
            <div className="relative w-full flex  items-center lg:w-3/5 xx:w-2/5 mt-10 lg:mt-0">
              <Searcher searchValue={searchValue} setSearchValue={setSearchValue} />
            </div>
          </div>

          <div
            className={`relative flex flex-col lg:flex-row justify-between mt-[60px] ${
              activeSlide ? 'gap-10 sm:gap-20' : 'lg:gap-20'
            }`}>
            <div className="flex flex-col w-full lg:w-1/2">
              <div className="relative lg:w-full  max-w-[375px] bg-no-repeat bg-bottom  hidden lg:block ">
                <div className="absolute w-full bottom-[-34px] h-1/2 bg-no-repeat bg-bottom bg-[url('/public/img/svg/trading_frame_bottom.svg')]"></div>
                <ul className="relative  flex-col pb-8  font-bold text-xl uppercase  lg:flex  border-r-2 border-white  ">
                  {!loadingRubric &&
                    rubricList.data &&
                    rubricList.data.length &&
                    rubricList.data.map((item) => (
                      <li
                        key={item.attributes.name}
                        className={`cursor-pointer py-[22px] ${
                          activeMenuIndex == item.id ? 'text-green active' : ''
                        }  trading-item`}
                        onClick={() => {
                          setActiveMenuIndex(item.id);
                        }}>
                        <div
                          className={`${
                            activeMenuIndex == item.id ? 'w-[50px] h-[2px] mr-5' : 'w-[0px] h-[0px]'
                          } bg-green my-auto  inline-block align-middle duration-200`}></div>
                        {item.attributes.name}
                      </li>
                    ))}

                  {/* <div className="absolute top-0 right-0 pointer-events-none">
                <SvgSprite src="/img/svg/trading_frame.svg" width={375} height={378} />
              </div> */}
                </ul>
              </div>
              <div className="flex lg:hidden    ">
                <ul className="no-select relative  flex  w-full font-bold text-xs sm:text-lg uppercase  lg:flex  border-b-2 border-white  overflow-x-scroll hide-scrollbar">
                  {!loadingRubric &&
                    rubricList.data &&
                    rubricList.data.length &&
                    rubricList.data.map((item) => (
                      <li
                        key={item.attributes.name}
                        className={`cursor-pointer px-[15px] pb-[24px]  sm:px-[25px] whitespace-nowrap ${
                          activeMenuIndex == item.id ? 'text-green active' : ''
                        }  trading-item`}
                        onClick={() => {
                          setActiveMenuIndex(item.id);
                        }}>
                        {item.attributes.name}
                      </li>
                    ))}

                  {/* <div className="absolute top-0 right-0 pointer-events-none">
                <SvgSprite src="/img/svg/trading_frame.svg" width={375} height={378} />
              </div> */}
                </ul>
              </div>
              <div className=" hidden lg:block  lg:mt-[112px] w-full lg:max-w-[375px]">
                <Link to={t('education.downloadLink')} target="_blank" rel="noopener noreferrer">
                  <Button
                    value={t('education.button')}
                    width={`w-full`}
                    icon={<SvgSprite src="/img/svg/icons/arrow.svg" width={27} height={20} />}
                  />
                </Link>
              </div>
            </div>

            <div className={` ${activeSlide ? ' w-full lg:w-4/5 ' : 'hidden'}`}>
              {currentId && (
                <SinglePage setActiveSlide={setActiveSlide} activeSlide={activeSlide} />
              )}
            </div>

            {/* <div
              className={`relative flex flex-wrap justify-between content-start gap-5 ${
                activeSlide ? 'w-full lg:w-1/4 min-h-[1700px]' : 'w-full  min-h-[1100px]'
              }   mt-7 lg:mt-0  overflow-y-scroll hide-scrollbar`}>
              {mockData.map((item, index) => (
                <Card
                  setActiveSlide={() => setActiveSlide(!activeSlide)}
                  activeSlide={activeSlide}
                  key={index}
                />
              ))}
            </div> */}
            <div
              className={`relative  ${
                activeSlide
                  ? 'w-full lg:w-2/6 lg:min-h-screen sm:max-h-[900px]  lg:max-h-[1200px] '
                  : 'w-full  min-h-screen '
              }   mt-7 lg:mt-0  overflow-y-scroll overflow-x-hidden hide-scrollbar `}>
              <div
                className={`grid ${
                  activeSlide
                    ? 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-1 gap-5'
                    : 'grid-cols-1 sm:grid-cols-3 xl:grid-cols-4  gap-5'
                } h-full content-start`}>
                {!loading && data && data.length
                  ? data.map((item) => {
                      return (
                        <Card
                          setActiveSlide={setActiveSlide}
                          activeSlide={activeSlide}
                          id={item.id}
                          key={item.attributes.name + item.id}
                          {...item.attributes}
                        />
                      );
                    })
                  : loading
                  ? Array.from(new Array(8)).map((el, index) => <SkeletonCard key={index} />)
                  : 'No matching article found'}
              </div>
            </div>
          </div>

          <SubMin
            title={t('home.hero.subscribe.title')}
            placeholder={t('home.hero.subscribe.placeholder')}
          />
        </div>
      </div>
    </section>
  );
};

export default Index;

// MENU ITEMS

{
  /* <li
className={`cursor-pointer px-[15px] pb-[24px]  sm:px-[25px] whitespace-nowrap ${
  activeMenuIndex == 1 ? 'text-green active' : ''
}  trading-item`}
onClick={() => {
  setActiveMenuIndex(1);
}}>
{t('education.menu.first')}
</li>
<li
className={`cursor-pointer px-[15px] pb-[24px] sm:px-[25px]   whitespace-nowrap ${
  activeMenuIndex == 2 ? 'text-green active' : ''
}  trading-item`}
onClick={() => {
  setActiveMenuIndex(2);
}}>
{t('education.menu.second')}
</li>
<li
className={`cursor-pointer px-[15px] pb-[24px] sm:px-[25px]  whitespace-nowrap  ${
  activeMenuIndex == 3 ? 'text-green active' : ''
}  trading-item`}
onClick={() => {
  setActiveMenuIndex(3);
}}>
{t('education.menu.third')}
</li>
<li
className={`cursor-pointer px-[15px] pb-[24px] sm:px-[25px]  whitespace-nowrap  ${
  activeMenuIndex == 4 ? 'text-green active' : ''
}  trading-item`}
onClick={() => {
  setActiveMenuIndex(4);
}}>
{t('education.menu.fourth')}
</li>
<li
className={`cursor-pointer px-[15px] pb-[24px] sm:px-[25px]  whitespace-nowrap  ${
  activeMenuIndex == 5 ? 'text-green active' : ''
}  trading-item`}
onClick={() => {
  setActiveMenuIndex(5);
}}>
{t('education.menu.fifth')}
</li>
<li
className={`cursor-pointer px-[15px] pb-[24px] sm:px-[25px]  whitespace-nowrap  ${
  activeMenuIndex == 6 ? 'text-green active' : ''
}  trading-item`}
onClick={() => {
  setActiveMenuIndex(6);
}}>
{t('education.menu.sixth')}
</li>
<li
className={`cursor-pointer px-[15px] pb-[24px] sm:px-[25px]  whitespace-nowrap  ${
  activeMenuIndex == 7 ? 'text-green active' : ''
}  trading-item`}
onClick={() => {
  setActiveMenuIndex(7);
}}>
{t('education.menu.seventh')}
</li>
<li
className={`cursor-pointer px-[15px] pb-[24px] sm:px-[25px]  whitespace-nowrap  ${
  activeMenuIndex == 8 ? 'text-green active' : ''
}  trading-item`}
onClick={() => {
  setActiveMenuIndex(8);
}}>
{t('education.menu.eight')}
</li> */
}
