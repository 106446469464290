import React from 'react';
import ContentLoader from 'react-content-loader';

const Skeleton = ({ props }) => (
  <ContentLoader
    speed={2}
    width="100%"
    height="260px"
    backgroundColor="#1e1e31"
    foregroundColor="#141422">
    {/* Основной прямоугольник */}
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
  </ContentLoader>
);

export default Skeleton;
