import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from './Card';
import useScreenSize from '../../../hooks/useScreenSize';
import SvgSprite from '../../ui/SvgSprite';

const Index = () => {
  const { t } = useTranslation();
  const screenSize = useScreenSize();

  const mockData = [
    {
      icon: (
        <SvgSprite
          src={`/img/svg/trading/help.svg`}
          fill={'none'}
          width={screenSize < 800 ? 37 : 50}
          height={screenSize < 800 ? 37 : 50}
        />
      ),
      desc: t('trading.cards.first.title'),
    },
    {
      icon: (
        <SvgSprite
          src={`/img/svg/trading/speed.svg`}
          fill={'none'}
          width={screenSize < 800 ? 37 : 50}
          height={screenSize < 800 ? 37 : 50}
        />
      ),
      desc: t('trading.cards.second.title'),
    },
    {
      icon: (
        <SvgSprite
          src={`/img/svg/trading/arrows_rotate.svg`}
          fill={'none'}
          width={screenSize < 800 ? 37 : 50}
          height={screenSize < 800 ? 37 : 50}
        />
      ),
      desc: t('trading.cards.third.title'),
    },
    {
      icon: (
        <SvgSprite
          src={`/img/svg/trading/arrows_up_down.svg`}
          fill={'none'}
          width={screenSize < 800 ? 37 : 50}
          height={screenSize < 800 ? 37 : 50}
        />
      ),
      desc: t('trading.cards.fourth.title'),
    },
  ];

  return (
    <section className="relative pt-[104px]">
      {/* <div className="z-[-1] pointer-events-none absolute top-0 left-0 w-full h-full bg-snippet bg-net opacity-10 bg-[url('/public/img/bg_net.svg')]"></div> */}
      <div className="container">
        <div className="relative grid grid-cols-2 grid-rows-2 w-full gap-5">
          {mockData.map((item) => (
            <Card key={item.icon + item.desc} icon={item.icon} desc={item.desc} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Index;
