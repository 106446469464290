import React from 'react';

const Slide = ({ title, desc, icon }) => {
  return (
    <div className="animate-fadeLeftRight rounded p-7 bg-black-300 ">
      {icon}
      <p className="mt-5  sm:mt-7  text-white text-[22px]  sm:text-[26px] lg:text-[26px]  xl:text-[30px]  leading-[22px] sm:leading-[26px]   xl:leading-[30px] uppercase font-bold w-full lg:w-3/4">
        {title}
      </p>
      <p className="mt-5  sm:mt-7   leading-[15px] sm:leading-[21px] w-full lg:w-3/4 font-cera text-xs sm:text-base xl:text-[18px]">
        {desc}
      </p>
    </div>
  );
};

export default Slide;
