import { useEffect } from 'react';
import SvgSprite from '../components/ui/SvgSprite';
// import i18n from '../locales/i18n';
import { useTranslation } from 'react-i18next';

const useGetSocials = (socials, order, iconSize) => {
  const { t, i18n } = useTranslation();

  iconSize = iconSize || 20;

  useEffect(() => {}, [i18n.language]);

  const social = [
    {
      name: t('footer.social.fifth.title'),
      link: 'https://twitter.com/1ex_com',
      icon: <SvgSprite src="/img/svg/social/twitter.svg" width={iconSize} height={iconSize} />,
    },
    {
      name: 'mail',
      link: '',
      icon: <SvgSprite src="/img/svg/social/mail.svg" width={iconSize} height={iconSize} />,
    },
    {
      name: t('footer.social.second.title'),
      link: 'https://t.me/oneexcom',
      icon: <SvgSprite src="/img/svg/social/telegram.svg" width={iconSize} height={iconSize} />,
    },
    {
      name: t('footer.social.fourth.title'),
      link: 'https://www.linkedin.com/company/1exboard/',
      icon: <SvgSprite src="/img/svg/social/linkedin.svg" width={iconSize} height={iconSize} />,
    },
    {
      name: t('footer.social.first.title'),
      link: 'https://medium.com/@1ex_com',
      icon: <SvgSprite src="/img/svg/social/medium.svg" width={iconSize} height={iconSize} />,
    },
    {
      name: t('footer.social.sixth.title'),
      link: 'https://www.instagram.com/1ex_com/',
      icon: <SvgSprite src="/img/svg/social/instagram.svg" width={iconSize} height={iconSize} />,
    },
    {
      name: t('footer.social.third.title'),
      link: 'https://www.youtube.com/@1ex_com',
      icon: <SvgSprite src="/img/svg/social/youtube.svg" width={iconSize} height={iconSize} />,
    },
  ];
  if (!socials) return social;

  // Приведение названий соцсетей из аргументов к нижнему регистру
  socials = socials.map((item) => item.toLowerCase());

  let filteredSocial = social.filter((item) => socials.includes(item.name.toLowerCase()));

  if (order) {
    // Сортировка массива соцсетей в соответствии с порядком из аргумента
    filteredSocial = filteredSocial.sort((a, b) => {
      const indexA = socials.indexOf(a.name.toLowerCase());
      const indexB = socials.indexOf(b.name.toLowerCase());
      return indexA - indexB;
    });
  }

  return filteredSocial;
};

export default useGetSocials;
