import React, { useState, useEffect } from 'react';
import SvgSprite from '../../ui/SvgSprite';
import markdownToHtml from '../../../helpers/markdownToHtml';

const Question = ({ quest, answer }) => {
  const [active, setActive] = useState(false);
  const [formattedQuestion, setFormattedQuestion] = useState('');
  const [formattedAnswer, setFormattedAnswer] = useState('');

  useEffect(() => {
    handleText();
  }, []);

  const handleText = async () => {
    setFormattedQuestion(await markdownToHtml(quest));
    setFormattedAnswer(await markdownToHtml(answer));
  };

  return (
    <div className=" relative flex flex-1 grow-0 flex-col p-5 lg:p-[30px] rounded bg-black-300 ">
      <div className="relative flex">
        <div
          onClick={() => {
            setActive(!active);
          }}
          className="cursor-pointer w-[90%] text-xs sm:text-[18px] xxl:text-lg"
          dangerouslySetInnerHTML={{ __html: formattedQuestion }}
        />
        <button
          onClick={() => {
            setActive(!active);
          }}
          className="cursor-pointer absolute  top-1/2 translate-y-[-50%] right-0">
          <SvgSprite
            width={31}
            height={31}
            src={`/img/pages/faq/icons/${active ? 'minus_icon.svg' : 'plus_icon.svg'}`}
          />
        </button>
      </div>
      <div
        className={`markdown-block font-cera w-[90%] text-xs sm:text-[18px] xxl:text-lg duration-200  ${
          !active ? ' h-0 overflow-hidden mt-0' : '  mt-7'
        }`}
        dangerouslySetInnerHTML={{ __html: formattedAnswer }}
      />
    </div>
  );
};

export default Question;
