import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../components/ui/Button';
import SvgSprite from '../components/ui/SvgSprite';

const Success = () => {
  const { t } = useTranslation();
  const { name } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="relative bg-offset-x-60  bg-snippet bg-conic-blue bg-[url('/public/img/hero_bg_gradient.webp')]">
      <div className="container flex flex-col min-h-screen justify-center items-center animate-fadeBottomTop">
        <div className="uppercase text-center text-2xl sm:text-[70px] sm:leading-[70px]  lg:text-[90px] lg:leading-[90px] lg:w-5/6 font-bold ">
          {t(`success.${name}.title`)}
        </div>
        <p className="  text-center text-base sm:text-lg lg:text-2xl  mt-8 sm:mt-10  ">
          {t(`success.${name}.description`)}
        </p>
        <Link to="/" className=" mt-[70px]">
          <Button
            value={t(`success.${name}.button`)}
            icon={<SvgSprite src="/img/svg/icons/arrow.svg" width={27} height={20} />}
          />
        </Link>
      </div>
    </section>
  );
};

export default Success;
