import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate, Link } from 'react-router-dom';
import Button from '../../ui/Button';
import SvgSprite from '../../ui/SvgSprite';
import Item from './Item';

import useGetFaqList from '../../../hooks/fetch/faq/useGetFaqList';

const Index = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { faqList, loading, error } = useGetFaqList();

  const [formatData, setFormatData] = useState([]);

  useEffect(() => {
    if (!loading) {
      setFormatData(checkData(faqList.data));
    }
  }, [loading, faqList]);

  const checkData = (data) => {
    if (data && data.length) {
      if (data.length <= 1) {
        return [data];
      } else {
        const middle = data.length > 8 ? 4 : Math.ceil(data.length / 2);
        const firstHalf = data.slice(0, middle);
        const secondHalf = data.length > 8 ? data.slice(middle, 8) : data.slice(middle);
        return [firstHalf, secondHalf];
      }
    } else {
      return [];
    }
  };
  return (
    <section className="relative   lg:min-h-[600px] pt-10 lg:pt-[80px] ">
      {/* my-14*/}
      {/* <div className="z-[-1] pointer-events-none absolute top-0 left-0 w-full h-full bg-snippet bg-net opacity-10 bg-[url('/public/img/bg_net.svg')]"></div> */}
      <div className="flex justify-between flex-col-reverse lg:flex-row h-full container">
        <div className=" flex w-full flex-wrap lg:flex-nowrap lg:w-[60%] gap-5 p-[10px] sm:p-5  mt-11 lg:mt-0 bg-black-300 rounded">
          {!loading && formatData.length ? (
            <>
              <div className="flex flex-col w-full lg:w-1/2  gap-5">
                {formatData[0] &&
                  formatData[0].map((item) => (
                    <Item key={item.id + item.attributes.answer} {...item.attributes} />
                  ))}
              </div>
              <div className="flex flex-col w-full lg:w-1/2 gap-5">
                {formatData[1] &&
                  formatData[1].map((item) => (
                    <Item key={item.id + item.attributes.answer} {...item.attributes} />
                  ))}
              </div>
            </>
          ) : (
            <div className="flex flex-col justify-center items-center uppercase w-full lg:w-full gap-5">
              data is not available
            </div>
          )}
        </div>

        <div className="flex flex-col w-full lg:w-[30%] xl:w-1/3 ">
          <h2>{t('home.questions.title')}</h2>
          <div className=" mt-5 lg:mt-[60px]">
            <Link to="/faq">
              <Button
                value={t('home.questions.button')}
                icon={<SvgSprite src="/img/svg/icons/arrow.svg" width={27} height={28} />}
                width={`w-full sm:w-1/2 lg:w-full xl:w-[70%]`}
                align={`justify-between`}
              />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
  return (
    <section className="relative py-[50px] sm:py-[100px]  lg:py-[200px] sm:min-h-[704px]">
      {/* <div className="z-[-1] pointer-events-none absolute top-0 left-0 w-full h-full bg-snippet bg-net opacity-10 bg-[url('/public/img/bg_net.svg')]"></div> */}
      <div className="flex justify-between flex-col-reverse lg:flex-row container">
        <div className=" flex w-full flex-wrap lg:flex-nowrap lg:w-[60%] gap-5 p-5  mt-11 lg:mt-0 bg-black-300 rounded">
          {!loading && formatData.length ? (
            <>
              <div className="flex flex-col w-full lg:w-1/2  gap-5">
                {formatData[0].map((item) => (
                  <Item key={item.id + item.attributes.answer} {...item.attributes} />
                ))}
              </div>
              <div className="flex flex-col w-full lg:w-1/2 gap-5">
                {formatData[1].map((item) => (
                  <Item key={item.id + item.attributes.answer} {...item.attributes} />
                ))}
              </div>
            </>
          ) : (
            <div className="flex flex-col justify-center items-center uppercase w-full lg:w-full gap-5">
              data is not available
            </div>
          )}
        </div>

        <div className="flex flex-col w-full sm:w-1/2 lg:w-1/3 ">
          <h2>{t('home.questions.title')}</h2>
          <div className=" mt-5 lg:mt-[60px]">
            <Link to="/faq">
              <Button
                value={t('home.questions.button')}
                icon={<SvgSprite src="/img/svg/icons/arrow.svg" width={27} height={28} />}
                width={`w-full sm:w-4/5`}
                align={`justify-between`}
              />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Index;
