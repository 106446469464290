import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const Item = ({
  id,
  activeSlide,
  setActiveSlide,
  name,
  cover,
  cover_short,
  date,
  createdAt,
  text,
}) => {
  const img =
    cover_short?.data?.attributes.provider === 'local'
      ? process.env.REACT_APP_BACKEND_URL + cover_short?.data?.attributes.url
      : cover_short?.data?.attributes.url;
  return (
    <swiper-slide>
      <Link to={`/education/${id}`}>
        <div className="w-full aspect-[9/11] sm:aspect-[8/11] noselect group">
          <div className="card flex flex-col justify-between px-[10px]  py-5 lg:px-3 [clip-path:polygon(0_0,80%_0,100%_16.2%,100%_110%,0_110%)] md:[clip-path:polygon(0_0,80%_0,100%_16.2%,100%_110%,0_110%)] lg:[clip-path:polygon(0_0,80%_0,100%_16.2%,100%_110%,0_110%)]">
            <div className="absolute   right-[-30.3%] top-0 w-full rotate-[44deg] sm:rotate-[48deg] border-[1px] border-green"></div>
            <div className="w-[90%] ">
              <p className="font-bold text-base sm:text-[20px]">{name}</p>
              <p className="mt-3 text-xs sm:text-[13px]">{moment(date).format('MMMM DD, YYYY')}</p>
            </div>

            <div className="relative  rounded  overflow-hidden w-full h-[50%]">
              <img
                src={img}
                alt="education"
                className="w-full h-full object-cover"
                loading="lazy"
              />
            </div>
          </div>

          {/* <div className="absolute top-0 left-0 z-[-1]">
    <img src="/img/svg/education_card.svg" alt="" />
  </div> */}
        </div>
      </Link>
    </swiper-slide>
  );
};

export default Item;
