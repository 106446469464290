import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useGetEducationOne from '../../hooks/fetch/education/useGetEducationOne';
import markdownToHtml from '../../helpers/markdownToHtml';

const SinglePage = ({ setActiveSlide, activeSlide }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { i18n, t } = useTranslation();
  const [currentId, setCurrentId] = useState(id);
  const { educationOne, loading, error } = useGetEducationOne(currentId);
  const [data, setData] = useState({});
  const [formattedText, setFormattedText] = useState('');
  const currentLanguage = i18n.language;

  const img =
    data?.attributes?.cover?.data?.attributes.provider === 'local'
      ? process.env.REACT_APP_BACKEND_URL + data?.attributes?.cover?.data?.attributes.url
      : data?.attributes?.cover?.data?.attributes.url;

  useEffect(() => {
    window.scrollTo(0, 0);
    setCurrentId(id);
  }, [id]);

  useEffect(() => {
    if (!loading && educationOne.data) {
      setData(educationOne.data);
      setActiveSlide(true);
    } else {
      setData({});
      setActiveSlide(false);
    }
  }, [loading, educationOne]);

  useEffect(() => {
    if (data) {
      handleText();
    }
  }, [data]);

  useEffect(() => {
    if (activeSlide) {
      if (data.attributes?.localizations.data.length) {
        // setCurrentId(data.attributes?.localizations.data[0].id);
        navigate(`/education/${data.attributes?.localizations.data[0].id}`);
      } else {
      }
    }
  }, [currentLanguage]);

  const handleText = async () => {
    setFormattedText(await markdownToHtml(data?.attributes?.text));
  };

  return (
    <div className="w-full rounded p-5 sm:p-7 bg-black-300">
      <p className="  text-[22px] sm:text-4xl xxl:text-[50px]">{data?.attributes?.name}</p>
      <div className="w-full max-h-[400px] rounded overflow-hidden mt-5 sm:mt-8">
        <img src={img} alt={data?.attributes?.name} className="w-full" />
      </div>
      <p
        className="markdown-block font-cera text-xs sm:text-[20px] lg:text-base xxl:text-lg leading-[15.4px] sm:leading-6 lg:leading-[19.2px] xxl:leading-7 mt-[30px] sm:mt-10"
        dangerouslySetInnerHTML={{ __html: formattedText }}
      />
    </div>
  );
};

export default SinglePage;
