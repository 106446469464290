import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import SvgSprite from '../SvgSprite';

import getSocial from '../../../data/social';
// import createRecordList from '../../../services/sendGrid/createRecordList';

const SubMin = ({ title, placeholder, theme }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const styles = {
    theme: theme || 'green',
  };
  const socials = getSocial(
    [
      t('footer.social.first.title'),
      t('footer.social.second.title'),
      t('footer.social.third.title'),
      t('footer.social.fourth.title'),
      t('footer.social.fifth.title'),
    ],
    true,
  );

  const validateEmail = (inputEmail) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(inputEmail);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateEmail(email)) {
      const newData = {
        email: email.trim(),
        form_name: 'callback',
      };

      try {
        const msg = {
          message: {
            recipients: [
              {
                email: 'alexey@nocodered.ru',
              },
            ],
            global_metadata: {
              campaign_id: '703b94a2-bb62-11ee-a6e8-26cecad04d04',
            },
            body: {
              html: `<p>This message was sent from algo.1ex.com!</p><p>Email address ${email} has been added to the mailing list: 703b94a2-bb62-11ee-a6e8-26cecad04d04</p>`,
            },
            subject: 'Send from algo.1ex.com',
            from_email: 'info@1ex.com',
          },
        };

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/sender/mail`, {
          method: 'POST',
          headers: {
            'Content-Type': `application/json`,
            Accept: `application/json`,
            Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
          },
          body: JSON.stringify(msg),
        });

        setEmail('');
        setErrorMessage('');
        navigate('/success/subscription');
      } catch (error) {
        setErrorMessage('The email has not been sent');
        console.error('Ошибка при отправке формы:', error);
      }
    } else {
      setErrorMessage('Enter a valid email address');
    }
  };

  return (
    <div className="z-10 rounded px-7 lg:px-0 py-4 lg:py-2 bg-black-200 hidden sm:flex">
      <div className="lg:container  flex items-center justify-between lg:justify-normal  w-full  text-white">
        <div className="flex flex-col   lg:mr-10">
          <p className="font-cera flex">{title}</p>
          <p className="font-cera mt-[10px] text-[#9594A2] text-xs lg:text-sm whitespace-pre-line">
            {t('home.hero.subscribe.subtitle')}
          </p>
        </div>

        <form className="relative" onSubmit={handleFormSubmit}>
          <label>
            <input
              className={`rounded py-4 px-4 pr-10 min-w-[330px] font-cera border-2 border-${styles.theme} bg-black-200 placeholder-white placeholder-12 text-xs`}
              type="email"
              name="email"
              placeholder={placeholder}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrorMessage('');
              }}
            />
            <div
              className="absolute cursor-pointer right-2 top-2/4 translate-y-[-50%] flex items-center justify-center w-7 h-7 border-2 border-white rounded-full"
              onClick={handleFormSubmit}>
              <SvgSprite src="/img/svg/icons/arrow_small.svg" width={15} height={15} />
            </div>
          </label>
        </form>
        {errorMessage && <p className="text-white text-xs ml-2">{errorMessage}</p>}
        <div className="gap-4 ml-auto  hidden lg:flex">
          {socials.map((item) => (
            <Link
              to={item.link}
              key={item.name}
              className="w-[40px] h-[40px] flex justify-center items-center rounded-full duration-300 hover:bg-white fill-black">
              {item.icon}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubMin;
