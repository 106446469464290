import { Helmet } from "react-helmet";

import Hero from "../components/home/Hero";
import Trading from "../components/home/Trading";
import Education from "../components/home/Education";
import Pricing from "../components/home/Pricing";
import FAQ from "../components/home/FAQ";
import About from "../components/home/About";

const Home = () => {
    return (
        <>
            <Helmet>
                <title>Algo</title>
                <meta
                    name='description'
                    content='Welcome to Algo - Your Gateway to Algorithmic Trading Excellence. Discover cutting-edge solutions and elevate your trading experience with us.'
                />
                <meta property='og:title' content='Algo' />
                <meta
                    property='og:description'
                    content='Welcome to Algo - Your Gateway to Algorithmic Trading Excellence. Discover cutting-edge solutions and elevate your trading experience with us.'
                />
                <meta property='og:url' content={process.env.REACT_APP_DOMAIN} />
            </Helmet>
            <Hero />
            <Trading />
            <Education />
            <Pricing />
            <FAQ />
            <About />
        </>
    );
};

export default Home;
