import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Card from './Card';
import CardsList from './CardsList';
import SubMin from '../../ui/Subscription/SubMin';

import useGetBotsOne from '../../../hooks/fetch/bots/useGetBotsOne';
import markdownToHtml from '../../../helpers/markdownToHtml';

const Index = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { i18n, t } = useTranslation();
  const [currentId, setCurrentId] = useState(id);

  const currentLanguage = i18n.language;

  const { bot, loading, error } = useGetBotsOne(currentId);

  const [formattedDesc, setFormattedDesc] = useState('');
  console.log(bot);
  console.log(bot.attributes?.cover_background?.data?.attributes.provider);
  const img =
    bot.attributes?.cover_background?.data?.attributes?.provider === 'local'
      ? process.env.REACT_APP_BACKEND_URL + bot.attributes?.cover_background?.data?.attributes.url
      : bot.attributes?.cover_background?.data?.attributes.url;

  useEffect(() => {
    if (!loading) {
      if (error) {
        navigate('/404');
      }
      handleText();
    }
  }, [loading, bot]);

  useEffect(() => {
    if (bot.attributes?.localizations.data.length) {
      setCurrentId(bot.attributes?.localizations.data[0].id);
    }
  }, [currentLanguage]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setCurrentId(id);
  }, [id]);

  const handleText = async () => {
    setFormattedDesc(await markdownToHtml(bot.attributes?.description));
  };

  return (
    <section className="relative pt-[90px] sm:pt-[150px]  sm:pb-0">
      {/* <div className="z-[-1] pointer-events-none absolute top-0 left-0 w-full h-full bg-snippet bg-net opacity-10 bg-[url('/public/img/bg_net.svg')]"></div> */}
      <div className="relative container lg:h-screen flex flex-col-reverse lg:flex-row gap-[70px]">
        <CardsList currentId={currentId} />
        <div className="lg:sticky flex flex-col w-full lg:w-2/3  lg:overflow-y-scroll hide-scrollbar">
          <div className="w-full p-5 sm:p-7 lg:text-4xl  bg-black-300 rounded">
            <h4 className=" text-[22px] sm:text-4xl xxl:text-[50px] font-bold">
              {bot.attributes?.name}
            </h4>
            <div className="w-full  overflow-hidden mt-5   rounded">
              <img src={img} alt="robot" className="w-full object-cover" />
            </div>
            <p
              className=" font-cera text-xs sm:text-[20px] markdown-block xxl:text-lg mt-10  leading-[14.4px] sm:leading-6 xxl:leading-[30.17px]"
              dangerouslySetInnerHTML={{ __html: formattedDesc }}
            />
          </div>
        </div>
      </div>
      <SubMin
        title={t('home.hero.subscribe.title')}
        placeholder={t('home.hero.subscribe.placeholder')}
      />
    </section>
  );
};

export default Index;
