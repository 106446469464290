import { useState, useEffect } from 'react';
import instance from '../instance';

const useGetBotsList = () => {
  const [botsList, setBotsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const currentLanguage = localStorage.getItem("language");

  useEffect(() => {
    const fetchBotsList = async () => {
      try {
        const response = await instance.get(`/api/botss?populate=*&locale=${currentLanguage}`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
          },
        });
        setBotsList(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBotsList();
  }, [currentLanguage]);

  return { botsList, loading, error };
};

export default useGetBotsList;
