import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import SvgSprite from '../../ui/SvgSprite';

const SocialCard = ({ name, link, icon, desc, merge, titleTransform }) => {
  const card = useRef();

  const onCardMove = (e) => {
    const cardRect = card.current.getBoundingClientRect();
    const centerX = cardRect.left + cardRect.width / 2;
    const centerY = cardRect.top + cardRect.height / 2;
    const mouseX = e.clientX;
    const mouseY = e.clientY;

    //  const angleX = (centerY - mouseY) / 30;
    //  const angleY = (centerX - mouseX) / 30;

    const angleX = (centerY - mouseY) / 30;
    const angleY = (mouseX - centerX) / 30;

    card.current.style.transform = `rotateX(${angleX}deg) rotateY(${angleY}deg)`;
  };
  const onCardOut = (e) => {
    card.current.style.transition = '.3s';
    card.current.style.transform = 'rotateX(0deg) rotateY(0deg) scale(1)';
    card.current.style.filter = `brightness(1)`;
    setTimeout(() => {
      card.current.style.transition = 'none';
    }, 300);
  };
  return (
    <Link
      ref={card}
      to={link}
      className={`relative p-[30px] col-span-1 md:${merge}  border-2 border-blue  rounded-[20px] no-select  `}>
      <p className="flex items-center justify-center md:justify-normal font-bold  text-2xl gradient-text gradient-text--blue">
        <span className={`hidden md:block ${titleTransform}`}>{name}</span>
        <span className=" ml-0  md:ml-4">{icon}</span>
      </p>
      <p className=" mt-5 hidden md:block font-cera  text-sm lg:text-base leading-[19.2px] lg:w-[90%]">
        {desc}
      </p>

      <div
        className="hide md:block z-10 absolute top-0 left-0 w-full h-full"
        onMouseMove={(e) => {
          onCardMove(e);
        }}
        onMouseOut={(e) => {
          onCardOut(e);
        }}></div>
    </Link>
  );
};

export default SocialCard;
