import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import VerticalSlider from "./VerticalSlider";
import HorizontalSlider from "./HorizontalSlider";

import useScreenSize from "../../../hooks/useScreenSize";

const Index = () => {
    const { t } = useTranslation();
    const screenSize = useScreenSize();
    const [sliderAxis, setSliderAxis] = useState("y");
    useEffect(() => {
        if (screenSize < 576) {
            setSliderAxis("x");
        } else {
            setSliderAxis("y");
        }
    }, [window.innerWidth]);

    return (
        <section
            className="relative justify-between sm:h-screen sm:min-h-[922px] py-[90px] sm:py-[100px]  md:py-[100px] mt-[20px] bg-snippet xxxl:bg-contain   bg-[url('/public/img/about_frame.png')]"
            id='about'
        >
            {/* <div className="z-[-1] pointer-events-none absolute top-0 left-0 w-full h-full bg-snippet bg-net opacity-10 bg-[url('/public/img/bg_net.svg')]"></div> */}
            <div className='relative container flex flex-col  h-full'>
                <h2 className='w-full sm:mt-5 lg:mt-10'>{t("home.about.title")}</h2>

                {sliderAxis == "x" ? <HorizontalSlider /> : <VerticalSlider />}
            </div>
        </section>
    );
};

export default Index;
