import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SvgSprite from '../../../ui/SvgSprite';
import Button from '../../../ui/Button';
import Option from './Option';
import useScreenSize from '../../../../hooks/useScreenSize';
import { Link } from 'react-router-dom';

const PriceCard = ({ title, desc, options, popular, prices, link, annually }) => {
  const screenSize = useScreenSize();
  const { t } = useTranslation();

  if (popular) {
    return (
      <div className="relative w-full  lg:w-[33.33%] lg:max-w-[412px] xxl:max-w-[444px]  ">
        <div className=" text-black uppercase font-bold  bg-green  flex justify-start absolute z-[1] sm:z-[-1] text-xs lg:text-[26px] xxl:text-[30px]  top-0  lg:top-[-55px] left-1/2 translate-x-[-50%] sm:left-0 sm:top-[-25px]  sm:translate-x-0 lg:left-0 sm:w-max lg:w-full py-2 px-[26px] lg:py-4 lg:px-7  lg:pb-[143px] rounded-[5px] sm:rounded sm:rounded-b-none lg:rounded">
          {t('home.pricing.price_cards.popular_text')}
        </div>
        <div className="relative flex flex-col sm:flex-row  lg:flex-col p-7 pt-[60px] sm:pt-7 sm:pb-[100px] lg:pb-7 w-full bg-black-300 rounded overflow-hidden">
          <div className="flex flex-col w-full sm:w-1/2 lg:w-full">
            <div className="relative self-center uppercase font-bold text-center sm:text-left lg:text-center text-[22px] sm:text-[40px] gradient-text gradient-text--blue  w-full ">
              {title}
              <div
                className="pointer-events-none absolute top-[-10px] right-[-20%]  lg:right-[-10%] w-full h-full  min-h-[52px]  bg-right-top  bg-no-repeat"
                style={
                  screenSize > 1300 //768
                    ? { backgroundImage: `url('/img/svg/pricing_zigzag.svg')` }
                    : screenSize < 576
                    ? { backgroundImage: `url('/img/svg/pricing_zigzag_mobile.svg')` }
                    : {}
                }></div>
              {/* <div className="absolute  top-0 right-0">
    <SvgSprite src="/img/svg/pricing_zigzag.svg" />
  </div> */}
            </div>
            <div className="relative self-center text-center sm:text-left lg:text-center text-base xxl:text-[18px] font-normal leading-[18px] w-full sm:w-3/4 lg:w-1/2 sm:mr-auto lg:mr-0">
              {desc}
              <div
                className="pointer-events-none absolute bottom-[-20px] left-[-20%]  lg:left-[-66%] w-full h-full min-h-[52px]  bg-left-bottom  bg-no-repeat "
                style={
                  screenSize > 1300 //768
                    ? { backgroundImage: `url('/img/svg/pricing_zigzag_bottom.svg')` }
                    : screenSize < 576
                    ? { backgroundImage: `url('/img/svg/pricing_zigzag_mobile.svg')` }
                    : {}
                }></div>
            </div>
          </div>
          <div className="relative flex flex-col lg:items-center lg:justify-center mt-[45px] sm:mt-0 lg:mt-[70px] sm:absolute lg:relative sm:bottom-[120px] sm:left-7 lg:left-auto lg:bottom-auto ">
            <div className="  uppercase font-roboto font-bold text-[22px] sm:text-4xl ">
              ${!annually ? prices.month : prices.annually}
            </div>

            <div className="flex justify-center items-center  py-[5px] px-[8px] mt-2 text-xs bg-black-400  rounded-[5px] w-max">
              {t('home.pricing.price_cards.billed_text')} $
              {!annually
                ? `${prices.annually} ${t('home.pricing.price_cards.annually_text')}`
                : `${prices.month} ${t('home.pricing.price_cards.monthly_text')}`}
            </div>
          </div>
          <ul className="flex flex-col gap-3  lg:gap-5 mt-[23px]  sm:mt-0  lg:mt-[30px] pb-5 w-full sm:w-1/2 lg:w-full  font-roboto   ">
            {options.map((item) => (
              <Option key={item.title + item.desc} {...item} />
            ))}
          </ul>

          <div className="relative sm:absolute lg:relative sm:bottom-[20px] lg:bottom-auto sm:left-1/2 sm:translate-x-[-50%] flex flex-col self-center mt-10 w-full sm:w-[90%] lg:w-full">
            <div className="">
              <Link to="https://account.1ex.com/ru/auth/signup">
                <Button
                  value={t('home.pricing.price_cards.second.button')}
                  width={'w-full'}
                  textSize={`text-base lg:text-[18px]`}
                />
              </Link>
            </div>
            <div className="self-center mt-3 text-xs">
              {t('home.pricing.price_cards.second.text_under_button')}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="relative flex flex-col sm:flex-row  lg:flex-col p-7  sm:pt-7 sm:pb-7 lg:pb-7 lg:pt-10 w-full   lg:w-[33.33%] lg:max-w-[412px] xxl:max-w-[444px] lg:min-h-[840px] xl:min-h-[850px] lg:justify-start bg-black-300 rounded overflow-hidden ">
        <div className="flex flex-col w-full sm:w-1/2 lg:w-full">
          <div className="relative self-center uppercase font-bold text-center sm:text-left lg:text-center text-[22px] sm:text-[40px] gradient-text gradient-text--blue  w-full ">
            {title}
            <div
              className="pointer-events-none absolute top-[-10px] right-[-20%]  lg:right-[-12%] w-full h-full  min-h-[52px]  bg-right-top  bg-no-repeat"
              style={
                screenSize > 1300 //768
                  ? { backgroundImage: `url('/img/svg/pricing_zigzag.svg')` }
                  : screenSize < 576
                  ? { backgroundImage: `url('/img/svg/pricing_zigzag_mobile.svg')` }
                  : {}
              }></div>
            {/* <div className="absolute  top-0 right-0">
                   <SvgSprite src="/img/svg/pricing_zigzag.svg" />
                 </div> */}
          </div>
          <div className="relative self-center text-center sm:text-left lg:text-center text-base xxl:text-[18px] font-normal leading-[18px] w-full sm:w-3/4 lg:w-1/2 sm:mr-auto lg:mr-0">
            {desc}
            <div
              className="pointer-events-none absolute bottom-[-20px] left-[-20%]  lg:left-[-65%] w-full h-full min-h-[52px]  bg-left-bottom  bg-no-repeat"
              style={
                screenSize > 1300 //768
                  ? { backgroundImage: `url('/img/svg/pricing_zigzag_bottom.svg')` }
                  : screenSize < 576
                  ? { backgroundImage: `url('/img/svg/pricing_zigzag_mobile.svg')` }
                  : {}
              }></div>
          </div>
        </div>
        <div className="relative flex flex-col lg:justify-center lg:items-center sm:absolute lg:relative sm:bottom-[50px] sm:left-7 lg:left-auto lg:bottom-auto mt-[45px] sm:mt-0 lg:mt-[70px]   ">
          <div className=" mt-0  uppercase font-roboto font-bold text-[22px] sm:text-4xl">
            ${!annually ? prices.month : prices.annually}
          </div>
          <div className="flex justify-center items-center  py-[5px] px-[8px] mt-2 text-xs bg-black-400  rounded-[5px] w-max">
            {t('home.pricing.price_cards.billed_text')} $
            {!annually
              ? `${prices.annually} ${t('home.pricing.price_cards.annually_text')}`
              : `${prices.month} ${t('home.pricing.price_cards.monthly_text')}`}
          </div>
        </div>
        <ul className="flex flex-col  gap-3 lg:gap-5 mt-[23px] sm:mt-0 lg:mt-[30px] pb-5 w-full sm:w-1/2 lg:w-full  font-roboto    ">
          {options.map((item) => (
            <Option key={item.title + item.desc} {...item} />
          ))}
        </ul>
      </div>
    );
  }

  if (popular) {
    return (
      <div className="relative w-full  lg:w-[33.33%] lg:max-w-[412px] xxl:max-w-[444px]  ">
        <div className=" text-black uppercase font-bold  bg-green  flex justify-start absolute z-[1] sm:z-[-1] text-xs lg:text-xl  top-0  lg:top-[-55px] left-1/2 translate-x-[-50%] sm:left-0 sm:top-[-25px]  sm:translate-x-0 lg:left-0 sm:w-2/5 lg:w-full py-2 px-[26px] lg:py-4 lg:px-7  lg:pb-[143px] rounded-[5px] sm:rounded sm:rounded-b-none lg:rounded">
          {t('home.pricing.price_cards.popular_text')}
        </div>
        <div className="relative flex flex-col sm:flex-row  lg:flex-col p-7 pt-[60px] sm:pt-7 sm:pb-[100px] lg:pb-7 w-full bg-black-300 rounded overflow-hidden">
          <div className="flex flex-col w-full sm:w-1/2 lg:w-full">
            <div className="relative self-center uppercase font-bold text-center sm:text-left lg:text-center text-[22px] sm:text-[40px] gradient-text gradient-text--blue  w-full ">
              {title}
              <div
                className="pointer-events-none absolute top-[-10px] right-[-20%]  lg:right-[-10%] w-full h-full  min-h-[52px]  bg-right-top  bg-no-repeat"
                style={
                  screenSize > 1300 //768
                    ? { backgroundImage: `url('/img/svg/pricing_zigzag.svg')` }
                    : screenSize < 576
                    ? { backgroundImage: `url('/img/svg/pricing_zigzag_mobile.svg')` }
                    : {}
                }></div>
              {/* <div className="absolute  top-0 right-0">
     <SvgSprite src="/img/svg/pricing_zigzag.svg" />
   </div> */}
            </div>
            <div className="relative self-center text-center sm:text-left lg:text-center text-base lg:text-[18px] font-normal leading-[18px] w-full sm:w-3/4 lg:w-1/2 sm:mr-auto lg:mr-0">
              {desc}
              <div
                className="pointer-events-none absolute bottom-[-20px] left-[-20%]  lg:left-[-66%] w-full h-full min-h-[52px]  bg-left-bottom  bg-no-repeat "
                style={
                  screenSize > 1300 //768
                    ? { backgroundImage: `url('/img/svg/pricing_zigzag_bottom.svg')` }
                    : screenSize < 576
                    ? { backgroundImage: `url('/img/svg/pricing_zigzag_mobile.svg')` }
                    : {}
                }></div>
            </div>
          </div>

          <ul className="flex flex-col  gap-5 mt-[70px] sm:mt-0 lg:mt-[70px] pb-5 w-full sm:w-1/2 lg:w-2/3  font-roboto lg:border-b-[1px] border-[#F5F5F5BF]    ">
            {options.map((item) => (
              <Option key={item.title + item.desc} {...item} />
            ))}
          </ul>
          <div className="relative sm:absolute lg:relative sm:bottom-[120px] sm:left-7 lg:left-auto lg:bottom-auto">
            <div className=" mt-5 uppercase font-roboto font-bold text-[22px] sm:text-4xl">
              ${!annually ? prices.month : prices.annually}
            </div>

            <div className="flex justify-center items-center  py-[5px] px-[8px] mt-2 text-xs bg-black-400  rounded-[5px] w-max">
              {t('home.pricing.price_cards.billed_text')} $
              {!annually
                ? `${prices.annually} ${t('home.pricing.price_cards.annually_text')}`
                : `${prices.month} ${t('home.pricing.price_cards.monthly_text')}`}
            </div>
          </div>

          <div className="relative sm:absolute lg:relative sm:bottom-[20px] lg:bottom-auto sm:left-1/2 sm:translate-x-[-50%] flex flex-col self-center mt-10 w-full sm:w-[90%] lg:w-full">
            <div className="">
              <Link to="https://account.1ex.com/ru/auth/signup">
                <Button
                  value={t('home.pricing.price_cards.second.button')}
                  width={'w-full'}
                  textSize={`text-base lg:text-[18px]`}
                />
              </Link>
            </div>
            <div className="self-center mt-3 text-xs">
              {t('home.pricing.price_cards.second.text_under_button')}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="relative flex flex-col sm:flex-row  lg:flex-col p-7  sm:pt-7 sm:pb-7 lg:pb-7 lg:pt-10 w-full   lg:w-[33.33%] lg:max-w-[412px] xxl:max-w-[444px] lg:min-h-[890px] xl:min-h-[850px] lg:justify-start bg-black-300 rounded overflow-hidden ">
        <div className="flex flex-col w-full sm:w-1/2 lg:w-full">
          <div className="relative self-center uppercase font-bold text-center sm:text-left lg:text-center text-[22px] sm:text-[40px] gradient-text gradient-text--blue  w-full ">
            {title}
            <div
              className="pointer-events-none absolute top-[-10px] right-[-20%]  lg:right-[-12%] w-full h-full  min-h-[52px]  bg-right-top  bg-no-repeat"
              style={
                screenSize > 1300 //768
                  ? { backgroundImage: `url('/img/svg/pricing_zigzag.svg')` }
                  : screenSize < 576
                  ? { backgroundImage: `url('/img/svg/pricing_zigzag_mobile.svg')` }
                  : {}
              }></div>
            {/* <div className="absolute  top-0 right-0">
                    <SvgSprite src="/img/svg/pricing_zigzag.svg" />
                  </div> */}
          </div>
          <div className="relative self-center text-center sm:text-left lg:text-center text-base lg:text-[18px] font-normal leading-[18px] w-full sm:w-3/4 lg:w-1/2 sm:mr-auto lg:mr-0">
            {desc}
            <div
              className="pointer-events-none absolute bottom-[-20px] left-[-20%]  lg:left-[-65%] w-full h-full min-h-[52px]  bg-left-bottom  bg-no-repeat"
              style={
                screenSize > 1300 //768
                  ? { backgroundImage: `url('/img/svg/pricing_zigzag_bottom.svg')` }
                  : screenSize < 576
                  ? { backgroundImage: `url('/img/svg/pricing_zigzag_mobile.svg')` }
                  : {}
              }></div>
          </div>
        </div>

        <ul className="flex flex-col  gap-5 mt-[70px] sm:mt-0 lg:mt-[70px] pb-5 w-full sm:w-1/2 lg:w-2/3  font-roboto    ">
          {options.map((item) => (
            <Option key={item.title + item.desc} {...item} />
          ))}
        </ul>
        <div className="relative sm:absolute lg:relative sm:bottom-[50px] sm:left-7 lg:left-auto lg:bottom-auto lg:mt-auto lg:pt-5  lg:border-t-[1px] border-[#F5F5F5BF] ">
          <div className=" mt-0  uppercase font-roboto font-bold text-[22px] sm:text-4xl">
            ${!annually ? prices.month : prices.annually}
          </div>
          <div className="flex justify-center items-center  py-[5px] px-[8px] mt-2 text-xs bg-black-400  rounded-[5px] w-max">
            {t('home.pricing.price_cards.billed_text')} $
            {!annually
              ? `${prices.annually} ${t('home.pricing.price_cards.annually_text')}`
              : `${prices.month} ${t('home.pricing.price_cards.monthly_text')}`}
          </div>
        </div>
      </div>
    );
  }

  if (popular) {
    return (
      <div className="relative w-full  lg:w-[33.33%] lg:max-w-[412px] xxl:max-w-[444px]  ">
        <div className=" text-black uppercase font-bold  bg-green  flex justify-start absolute z-[1] sm:z-[-1] text-xs lg:text-xl  top-0  lg:top-[-55px] left-1/2 translate-x-[-50%] sm:left-0 sm:top-[-25px]  sm:translate-x-0 lg:left-0 sm:w-2/5 lg:w-full py-2 px-[26px] lg:py-4 lg:px-7  lg:pb-[143px] rounded-[5px] sm:rounded sm:rounded-b-none lg:rounded">
          {t('home.pricing.price_cards.popular_text')}
        </div>
        <div className="relative flex flex-col sm:flex-row  lg:flex-col p-7 pt-[60px] sm:pt-7 sm:pb-[100px] lg:pb-7 w-full bg-black-300 rounded overflow-hidden">
          <div className="flex flex-col w-full sm:w-1/2 lg:w-full">
            <div className="relative self-center uppercase font-bold text-center sm:text-left lg:text-center text-[22px] sm:text-[40px] gradient-text gradient-text--blue  w-full ">
              {title}
              <div
                className="pointer-events-none absolute top-[-10px] right-[-20%]  lg:right-[-10%] w-full h-full  min-h-[52px]  bg-right-top  bg-no-repeat"
                style={
                  screenSize > 1300 //768
                    ? { backgroundImage: `url('/img/svg/pricing_zigzag.svg')` }
                    : screenSize < 576
                    ? { backgroundImage: `url('/img/svg/pricing_zigzag_mobile.svg')` }
                    : {}
                }></div>
              {/* <div className="absolute  top-0 right-0">
      <SvgSprite src="/img/svg/pricing_zigzag.svg" />
    </div> */}
            </div>
            <div className="relative self-center text-center sm:text-left lg:text-center text-base lg:text-[18px] font-normal leading-[18px] w-full sm:w-3/4 lg:w-1/2 sm:mr-auto lg:mr-0">
              {desc}
              <div
                className="pointer-events-none absolute bottom-[-20px] left-[-20%]  lg:left-[-66%] w-full h-full min-h-[52px]  bg-left-bottom  bg-no-repeat "
                style={
                  screenSize > 1300 //768
                    ? { backgroundImage: `url('/img/svg/pricing_zigzag_bottom.svg')` }
                    : screenSize < 576
                    ? { backgroundImage: `url('/img/svg/pricing_zigzag_mobile.svg')` }
                    : {}
                }></div>
            </div>
          </div>

          <ul className="flex flex-col  gap-5 mt-[70px] sm:mt-0 lg:mt-[70px] pb-5 w-full sm:w-1/2 lg:w-2/3  font-roboto lg:border-b-[1px] border-[#F5F5F5BF]    ">
            {options.map((item) => (
              <Option key={item.title + item.desc} {...item} />
            ))}
          </ul>
          <div className="relative sm:absolute lg:relative sm:bottom-[120px] sm:left-7 lg:left-auto lg:bottom-auto">
            <div className=" mt-5 uppercase font-roboto font-bold text-[22px] sm:text-4xl">
              ${!annually ? prices.month : prices.annually}
            </div>

            <div className="flex justify-center items-center  py-[5px] px-[8px] mt-2 text-xs bg-black-400  rounded-[5px] w-max">
              {t('home.pricing.price_cards.billed_text')} $
              {!annually
                ? `${prices.annually} ${t('home.pricing.price_cards.annually_text')}`
                : `${prices.month} ${t('home.pricing.price_cards.monthly_text')}`}
            </div>
          </div>

          <div className="relative sm:absolute lg:relative sm:bottom-[20px] lg:bottom-auto sm:left-1/2 sm:translate-x-[-50%] flex flex-col self-center mt-10 w-full sm:w-[90%] lg:w-full">
            <div className="">
              <Link to="https://account.1ex.com/ru/auth/signup">
                <Button
                  value={t('home.pricing.price_cards.second.button')}
                  width={'w-full'}
                  textSize={`text-base lg:text-[18px]`}
                />
              </Link>
            </div>
            <div className="self-center mt-3 text-xs">
              {t('home.pricing.price_cards.second.text_under_button')}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="relative flex flex-col sm:flex-row  lg:flex-col p-7  sm:pt-7 sm:pb-7 lg:pb-7 lg:pt-10 w-full   lg:w-[33.33%] lg:max-w-[412px] xxl:max-w-[444px]  bg-black-300 rounded overflow-hidden ">
        <div className="flex flex-col w-full sm:w-1/2 lg:w-full">
          <div className="relative self-center uppercase font-bold text-center sm:text-left lg:text-center text-[22px] sm:text-[40px] gradient-text gradient-text--blue  w-full ">
            {title}
            <div
              className="pointer-events-none absolute top-[-10px] right-[-20%]  lg:right-[-12%] w-full h-full  min-h-[52px]  bg-right-top  bg-no-repeat"
              style={
                screenSize > 1300 //768
                  ? { backgroundImage: `url('/img/svg/pricing_zigzag.svg')` }
                  : screenSize < 576
                  ? { backgroundImage: `url('/img/svg/pricing_zigzag_mobile.svg')` }
                  : {}
              }></div>
            {/* <div className="absolute  top-0 right-0">
                     <SvgSprite src="/img/svg/pricing_zigzag.svg" />
                   </div> */}
          </div>
          <div className="relative self-center text-center sm:text-left lg:text-center text-base lg:text-[18px] font-normal leading-[18px] w-full sm:w-3/4 lg:w-1/2 sm:mr-auto lg:mr-0">
            {desc}
            <div
              className="pointer-events-none absolute bottom-[-20px] left-[-20%]  lg:left-[-65%] w-full h-full min-h-[52px]  bg-left-bottom  bg-no-repeat"
              style={
                screenSize > 1300 //768
                  ? { backgroundImage: `url('/img/svg/pricing_zigzag_bottom.svg')` }
                  : screenSize < 576
                  ? { backgroundImage: `url('/img/svg/pricing_zigzag_mobile.svg')` }
                  : {}
              }></div>
          </div>
        </div>

        <ul className="flex flex-col  gap-5 mt-[70px] sm:mt-0 lg:mt-[70px] pb-5 w-full sm:w-1/2 lg:w-2/3  font-roboto lg:border-b-[1px] border-[#F5F5F5BF]    ">
          {options.map((item) => (
            <Option key={item.title + item.desc} {...item} />
          ))}
        </ul>
        <div className="relative sm:absolute lg:relative sm:bottom-[50px] sm:left-7 lg:left-auto lg:bottom-auto">
          <div className=" mt-0 lg:mt-5 uppercase font-roboto font-bold text-[22px] sm:text-4xl">
            ${!annually ? prices.month : prices.annually}
          </div>
          <div className="flex justify-center items-center  py-[5px] px-[8px] mt-2 text-xs bg-black-400  rounded-[5px] w-max">
            {t('home.pricing.price_cards.billed_text')} $
            {!annually
              ? `${prices.annually} ${t('home.pricing.price_cards.annually_text')}`
              : `${prices.month} ${t('home.pricing.price_cards.monthly_text')}`}
          </div>
        </div>
      </div>
    );
  }
};

export default PriceCard;
