import React, { useEffect, useState } from 'react';
import SvgSprite from '../ui/SvgSprite';
import useScreenSize from '../../hooks/useScreenSize';

const Logo = () => {
  const screenSize = useScreenSize();
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  useEffect(() => {
    if (screenSize < 1025) {
      setWidth(47);
      setHeight(50);
    } else {
      setWidth(72);
      setHeight(80);
    }
  }, [window.innerWidth]);

  return <SvgSprite src="/img/svg/logo.svg" width={width} height={height} />;
};

export default Logo;
