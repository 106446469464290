import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '../../ui/Button';
import SvgSprite from '../../ui/SvgSprite';
import Slider from './Slider';

const Index = () => {
  const { t } = useTranslation();
  return (
    <section className="relative py-[50px] sm:py-[90px] " id="education">
      {/* <div className="z-[-1] pointer-events-none absolute top-0 left-0 w-full h-full bg-snippet bg-net opacity-10 bg-[url('/public/img/bg_net.svg')]"></div> */}
      <div className=" container flex flex-col lg:flex-row items-start justify-between">
        <div className="flex flex-col w-full lg:w-1/3">
          <h2>{t('home.education.title')}</h2>
          <p className=" uppercase mt-5 lg:mt-[60px] mb-0 sm:mb-10 text-base sm:text-[20px] lg:text-[26px] xl:text-[30px]">
            {t('home.education.description')}
          </p>
          <div className="w-full hidden sm:block">
            <Link to="/education">
              <Button
                value={t('home.education.button')}
                icon={<SvgSprite src="/img/svg/icons/arrow.svg" width={27} height={20} />}
                align={'justify-between'}
                width={'sm:w-2/4 lg:w-full xl:w-[70%]'}
              />
            </Link>
          </div>
        </div>
        <div className="flex flex-col w-full mt-[60px] lg:w-[60%] lg:mt-0">
          <div className="hidden sm:grid grid-cols-4 mb-16 sm:text-[20px] x:text-[26px]  lg:text-[30px]     gap-5">
            <div className="flex justify-center items-center py-5 uppercase text-white font-bold rounded border-[1px] border-blue bg-[#1D1D2A]">
              {t('home.education.tags.first')}
            </div>
            <div className="flex justify-center items-center py-5 uppercase text-white font-bold rounded border-[1px] border-blue bg-[#1D1D2A]">
              {t('home.education.tags.second')}
            </div>
            <div className="flex justify-center items-center py-5 uppercase text-white font-bold rounded border-[1px] border-blue bg-[#1D1D2A]">
              {t('home.education.tags.third')}
            </div>
            <div className="flex justify-center items-center py-5 uppercase text-white font-bold rounded border-[1px] border-blue bg-[#1D1D2A]">
              {t('home.education.tags.fourth')}
            </div>
          </div>
          <Slider />
          <div className="flex  justify-center items-center w-full mt-12  p-5 sm:py-3 sm:px-[14px] text-xs sm:text-base xl:text-[18px] text-center font-cera rounded bg-black-300">
            {t('home.education.footer_text')}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Index;
