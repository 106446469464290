import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SvgSprite from '../../ui/SvgSprite';
import Button from '../../ui/Button';
import ItemMenu from './ItemMenu';
import ItemMenuMobile from './ItemMenuMobile';
import Slide from './Slide';
import useScreenSize from '../../../hooks/useScreenSize';

const Index = () => {
  const { t, i18n } = useTranslation();
  const screenSize = useScreenSize();
  const [activeSlideIndex, setActiveSlideIndex] = useState(1);
  const [slides, setSlides] = useState([]);
  const currentLanguage = i18n.language;
  const slidesData = [
    {
      id: 1,
      title: t('home.trading.slides.first.title'),
      items: [
        {
          parent_id: 1,
          icon: (
            <SvgSprite
              src="/img/svg/trading/connecting.svg"
              width={screenSize < 576 ? 35 : 50}
              height={screenSize < 576 ? 35 : 50}
            />
          ),
          title: t('home.trading.slides.first.first.title'),
          desc: t('home.trading.slides.first.first.description'),
        },
        {
          parent_id: 1,
          icon: (
            <SvgSprite
              src="/img/svg/trading/backtest.svg"
              width={screenSize < 576 ? 35 : 50}
              height={screenSize < 576 ? 35 : 50}
            />
          ),
          title: t('home.trading.slides.first.second.title'),
          desc: t('home.trading.slides.first.second.description'),
        },
        {
          parent_id: 1,
          icon: (
            <SvgSprite
              src="/img/svg/trading/protection.svg"
              width={screenSize < 576 ? 35 : 50}
              height={screenSize < 576 ? 35 : 50}
            />
          ),
          title: t('home.trading.slides.first.third.title'),
          desc: t('home.trading.slides.first.third.description'),
        },
        {
          parent_id: 1,
          icon: (
            <SvgSprite
              src="/img/svg/trading/help.svg"
              width={screenSize < 576 ? 35 : 50}
              height={screenSize < 576 ? 35 : 50}
              className="fill-none"
            />
          ),
          title: t('home.trading.slides.first.fourth.title'),
          desc: t('home.trading.slides.first.fourth.description'),
        },
      ],
    },
    {
      id: 2,
      title: t('home.trading.slides.second.title'),
      items: [
        {
          parent_id: 2,
          icon: (
            <SvgSprite
              src="/img/svg/trading/traders_one.svg"
              width={screenSize < 576 ? 35 : 50}
              height={screenSize < 576 ? 35 : 50}
            />
          ),
          title: t('home.trading.slides.second.first.title'),
          desc: t('home.trading.slides.second.first.description'),
        },
        {
          parent_id: 2,
          icon: (
            <SvgSprite
              src="/img/svg/trading/traders_two.svg"
              width={screenSize < 576 ? 35 : 50}
              height={screenSize < 576 ? 35 : 50}
            />
          ),
          title: t('home.trading.slides.second.second.title'),
          desc: t('home.trading.slides.second.second.description'),
        },
        {
          parent_id: 2,
          icon: (
            <SvgSprite
              src="/img/svg/trading/protection.svg"
              width={screenSize < 576 ? 35 : 50}
              height={screenSize < 576 ? 35 : 50}
            />
          ),
          title: t('home.trading.slides.second.third.title'),
          desc: t('home.trading.slides.second.third.description'),
        },
        {
          parent_id: 2,
          icon: (
            <SvgSprite
              src="/img/svg/trading/connecting.svg"
              width={screenSize < 576 ? 35 : 50}
              height={screenSize < 576 ? 35 : 50}
              className="fill-none"
            />
          ),
          title: t('home.trading.slides.second.fourth.title'),
          desc: t('home.trading.slides.second.fourth.description'),
        },
      ],
    },
    {
      id: 3,
      title: t('home.trading.slides.third.title'),
      items: [
        {
          parent_id: 3,
          icon: (
            <SvgSprite
              src="/img/svg/trading/hedge_one.svg"
              width={screenSize < 576 ? 35 : 50}
              height={screenSize < 576 ? 35 : 50}
            />
          ),
          title: t('home.trading.slides.third.first.title'),
          desc: t('home.trading.slides.third.first.description'),
        },
        {
          parent_id: 3,
          icon: (
            <SvgSprite
              src="/img/svg/trading/traders_two.svg"
              width={screenSize < 576 ? 35 : 50}
              height={screenSize < 576 ? 35 : 50}
            />
          ),
          title: t('home.trading.slides.third.second.title'),
          desc: t('home.trading.slides.third.second.description'),
        },
        {
          parent_id: 3,
          icon: (
            <SvgSprite
              src="/img/svg/trading/protection.svg"
              width={screenSize < 576 ? 35 : 50}
              height={screenSize < 576 ? 35 : 50}
            />
          ),
          title: t('home.trading.slides.third.third.title'),
          desc: t('home.trading.slides.third.third.description'),
        },
        {
          parent_id: 3,
          icon: (
            <SvgSprite
              src="/img/svg/trading/hedge_four.svg"
              width={screenSize < 576 ? 35 : 50}
              height={screenSize < 576 ? 35 : 50}
              className="fill-none"
            />
          ),
          title: t('home.trading.slides.third.fourth.title'),
          desc: t('home.trading.slides.third.fourth.description'),
        },
      ],
    },
    {
      id: 4,
      title: t('home.trading.slides.fourth.title'),
      items: [
        {
          parent_id: 4,
          icon: (
            <SvgSprite
              src="/img/svg/trading/developers_one.svg"
              fill="none"
              width={screenSize < 576 ? 35 : 50}
              height={screenSize < 576 ? 35 : 50}
            />
          ),
          title: t('home.trading.slides.fourth.first.title'),
          desc: t('home.trading.slides.fourth.first.description'),
        },
        {
          parent_id: 4,
          icon: (
            <SvgSprite
              src="/img/svg/trading/backtest.svg"
              width={screenSize < 576 ? 35 : 50}
              height={screenSize < 576 ? 35 : 50}
            />
          ),
          title: t('home.trading.slides.fourth.second.title'),
          desc: t('home.trading.slides.fourth.second.description'),
        },
        {
          parent_id: 4,
          icon: (
            <SvgSprite
              src="img/svg/trading/help.svg"
              width={screenSize < 576 ? 35 : 50}
              height={screenSize < 576 ? 35 : 50}
              className="fill-none"
            />
          ),
          title: t('home.trading.slides.fourth.third.title'),
          desc: t('home.trading.slides.fourth.third.description'),
        },
        {
          parent_id: 4,
          icon: (
            <SvgSprite
              src="/img/svg/trading/protection.svg"
              width={screenSize < 576 ? 35 : 50}
              height={screenSize < 576 ? 35 : 50}
              className="fill-none rotate-90"
            />
          ),
          title: t('home.trading.slides.fourth.fourth.title'),
          desc: t('home.trading.slides.fourth.fourth.description'),
        },
      ],
    },
  ];

  useEffect(() => {
    //Set current slides
    let obj = {};
    slidesData.filter((item) => {
      if (item.id == activeSlideIndex) {
        obj = item;
      }
    });

    if (obj) {
      setSlides(obj.items);
    }
  }, [activeSlideIndex, currentLanguage]);

  return (
    <section
      className="relative  flex items-center py-[50px] sm:py-[50px] sm:pt-[150px] "
      id="trading">
      <div className="absolute top-0 left-[50%] translate-x-[-50%] w-full h-full  bg-no-repeat bg-left-top xxxl:w-[1720px] xxxl:mx-auto bg-custom-trading"></div>
      {/* <div className="z-[-1] pointer-events-none absolute top-0 left-0 w-full h-full bg-snippet bg-net opacity-10 bg-[url('/public/img/bg_net.svg')]"></div> */}
      <div className="relative flex  container  flex-col w-full">
        <div className="flex flex-col justify-between lg:items-center lg:flex-row">
          <h2>{t('home.trading.title')}</h2>
          <p className=" w-full lg:w-1/3 uppercase text-base sm:text-[20px] lg:text-[26px] xl:text-[30px] mt-5 lg:mt-0">
            {t('home.trading.description')}
          </p>
          <div className="flex lg:hidden  mt-7 sm:mt-[80px]  ">
            <ul className="no-select relative  flex  w-full font-bold text-xs sm:text-lg uppercase  lg:flex  border-b-2 border-white  overflow-x-scroll hide-scrollbar">
              {slidesData.map((item) => (
                <ItemMenuMobile
                  key={item.title + item.id}
                  {...item}
                  activeSlideIndex={activeSlideIndex}
                  setActiveSlideIndex={setActiveSlideIndex}
                />
              ))}
            </ul>
          </div>
        </div>

        <div className="flex mt-14 flex-col-reverse justify-between w-full lg:flex-row">
          <div className="relative flex flex-col lg:w-[30%] xl:w-1/3">
            <div className="hidden lg:block relative lg:max-w-[260px] xxl:max-w-[375px]">
              <div className="absolute w-full bottom-[-34px] h-1/2 bg-no-repeat bg-bottom bg-[url('/public/img/svg/trading_frame_bottom.svg')]"></div>
              <ul className="relative  flex-col pb-8  font-bold text-[26px] xxl:text-[30px]  uppercase  lg:flex  border-r-2 border-white  ">
                {slidesData.map((item) => (
                  <ItemMenu
                    key={item.title + item.id}
                    {...item}
                    activeSlideIndex={activeSlideIndex}
                    setActiveSlideIndex={setActiveSlideIndex}
                  />
                ))}
              </ul>
            </div>
            <div className="w-full  mt-14 lg:mt-[120px] xl:mt-[100px] p-7 font-roboto bg-black-100 rounded ">
              <p className="uppercase"> {t('home.trading.system_requirements.title')}</p>
              <ul className="font-cera list-disc mt-5 pl-[18px] text-xs sm:text-base xxl:text-[18px] leading-6">
                <li>{t('home.trading.system_requirements.items.first')}</li>
                <li>{t('home.trading.system_requirements.items.second')}</li>
                <li>{t('home.trading.system_requirements.items.third')}</li>
                <li>{t('home.trading.system_requirements.items.fourth')}</li>
                <li>{t('home.trading.system_requirements.items.fifth')}</li>
                <li>{t('home.trading.system_requirements.items.sixth')}</li>
              </ul>
            </div>
          </div>

          <div className=" w-full lg:w-[60%]  grid grid-cols-1 grid-rows-4 sm:grid-cols-2 sm:grid-rows-2  gap-5">
            {slides.map((item, index) => (
              <Slide
                key={item.title + item.desc + item.parent_id}
                icon={item.icon}
                title={item.title}
                desc={item.desc}
              />
            ))}

            <div className="col-span-auto sm:col-span-2">
              <Link to="/trading">
                <Button
                  value={t('home.trading.button')}
                  width={'w-full'}
                  textSize={`text-base lg:text-[18px]`}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Index;
