import React from 'react';
import { Link } from 'react-router-dom';

const Item = ({ id, name, short_description, cover_interface }) => {
  const img =
    cover_interface.data?.attributes.provider === 'local'
      ? process.env.REACT_APP_BACKEND_URL + cover_interface.data?.attributes.url
      : cover_interface.data?.attributes.url;

  return (
    <Link to={`/trading/${id}`}>
      <div className="flex flex-col sm:flex-row justify-between sm:gap-[30px] lg:gap-0">
        <div className="w-full sm:w-2/5 lg:w-2/5  max-h-[280px] sm:max-h-[207px] lg:max-h-[140px] overflow-hidden rounded">
          <img src={img} alt={name} className="w-full h-full object-cover" />
        </div>
        <div className="flex flex-col w-full sm:w-3/5 lg:w-2/4 ">
          <p className=" text-base sm:text-[30px] lg:text-[26px] font-bold mt-5 md:mt-0">{name}</p>
          <p className=" text-xs sm:text-base  mt-5">{short_description}</p>
        </div>
      </div>
    </Link>
  );
};

export default Item;
