import React, { useEffect, useState } from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import SvgSprite from '../../ui/SvgSprite';
import Logo from '../../ui/Logo';
import useScreenSize from '../../../hooks/useScreenSize';
import { useTranslation } from 'react-i18next';

import Nav from './Nav';

const Index = () => {
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;
  const screenSize = useScreenSize();
  const location = useLocation();
  const navigate = useNavigate();

  const [openMenu, setOpenMenu] = useState(false);
  let firstBurgerLine = 'w-full h-[2px] bg-white transition-all duration-500';
  let secondBurgerLine = 'w-full h-[2px] bg-white transition-all duration-500';
  let thirdBurgerLine = 'w-full h-[2px] bg-white transition-all duration-500';
  let menuCLass =
    'absolute z-20 translate-y-0 bottom-full h-screen w-full transition duration-1000 pt-20 h-full bg-[#191927]';
  let menuCLassInner = 'container opacity-0 transition-all duration-300';
  if (openMenu) {
    firstBurgerLine = 'w-[34px] h-[2px] bg-white transition-all duration-500 opacity-0';
    secondBurgerLine = 'w-[32px] h-[2px] bg-white transition-all duration-500 rotate-45';
    thirdBurgerLine =
      'w-[32px] h-[2px] bg-white transition-all -translate-y-[9px]  duration-500 -rotate-45';
    menuCLass += ' translate-y-full';
    menuCLassInner = 'container opacity-1 transition-all duration-[2500ms] h-full overflow-y-auto';
  }

  useEffect(() => {
    const targetElement = document.querySelector('body');
    if (openMenu) {
      targetElement.classList.add('scrollBlockClass');
    } else {
      targetElement.classList.remove('scrollBlockClass');
    }
    return () => {
      targetElement.classList.remove('scrollBlockClass');
    };
  }, [openMenu]);

  useEffect(() => {
    setOpenMenu(false);
  }, [location]);

  const changeLanguage = () => {
    if (currentLanguage == 'en') {
      i18n.changeLanguage('ru');
      localStorage.setItem('language', 'ru');
    } else {
      i18n.changeLanguage('en');
      localStorage.setItem('language', 'en');
    }
  };

  return (
    <header className="  z-50 fixed top-0 left-0 w-full bg-no-repeat bg-[rgba(25,25,39,0.40)]">
      <div className="flex justify-between items-center container z-20">
        <HashLink to="/#top" className="z-30">
          <div className="flex items-center">
            <Logo />
            <p className="ml-6 text-[20px] sm:text-2xl  uppercase">ALGO</p>
          </div>
        </HashLink>
        <Nav />
        <div
          role="none"
          onClick={() => setOpenMenu(!openMenu)}
          className="relative z-40 w-[34px] h-[20px] flex-col justify-between flex lg:hidden">
          <span className={firstBurgerLine} />
          <span className={secondBurgerLine} />
          <span className={thirdBurgerLine} />
        </div>
      </div>
      <div
        className={`${
          openMenu ? 'flex' : 'hidden'
        } flex-col justify-between  fixed top-0 left-0 z-10 w-full h-full  bg-[#141422] overflow-y-scroll overflow-x-hidden  animate-moveTopBottom`}>
        <div className="container h-full flex flex-col justify-between  mt-[100px] pb-[44px] sm:pb-[76px]">
          <nav className="">
            <ul className="flex flex-col gap-5 uppercase text-[22px] sm:text-lg w-full ">
              <li className=" pb-5 border-b-[1px] border-blue w-full">
                <Link className="w-full" to={'https://1ex.com/'}>
                  {t('nav.first')}
                </Link>
              </li>
              <li className=" pb-5 border-b-[1px] border-blue">
                <HashLink smooth to={'/#trading'}>
                  {t('nav.second')}
                </HashLink>
              </li>
              <li className=" pb-5 border-b-[1px] border-blue">
                <HashLink smooth to={'/#education'}>
                  {t('nav.third')}
                </HashLink>
              </li>
              <li className=" pb-5 border-b-[1px] border-blue">
                <HashLink smooth to={'/#pricing'}>
                  {t('nav.fourth')}
                </HashLink>
              </li>
              <li className=" pb-5 border-b-[1px] border-blue">
                <HashLink smooth to={'/#about'}>
                  {t('nav.fifth')}
                </HashLink>
              </li>
            </ul>
          </nav>
          <div className="flex flex-col">
            <Link to="https://account.1ex.com/ru/auth/signup" className="block w-full">
              <button className="flex items-center justify-center w-full  text-black font-bold uppercase rounded py-3 px-4 bg-blue">
                {t('header.buttons.sign_in')}
              </button>
            </Link>

            <button
              className="flex items-center justify-center  text-white font-bold uppercase rounded py-3 px-4 bg-black-300 mt-3"
              onClick={() => changeLanguage()}>
              <div className="mr-[3px]">
                <SvgSprite
                  src={`/img/svg/languages/${currentLanguage}.svg`}
                  width={16}
                  height={12}
                />
              </div>
              {t('header.buttons.lang')}
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Index;
