import { useState, useEffect } from 'react';
import qs from 'qs';
import instance from '../instance';

const useGetEducationList = (searchValue, rubric) => {
  const [educationList, setEducationList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const currentLanguage = localStorage.getItem("language");

  let query = {};

  useEffect(() => {
    rubric = null;
  }, [currentLanguage]);

  //If the category is not selected, then search by these categories
  if (rubric) {
    query = qs.stringify(
      {
        filters: {
          $and: [
            {
              rubric: {
                id: {
                  $eq: rubric,
                },
              },
            },
            {
              name: {
                $containsi: searchValue,
              },
            },
          ],
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      },
    );
  } else {
    query = qs.stringify(
      {
        filters: {
          name: {
            $containsi: searchValue,
          },
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      },
    );
  }

  useEffect(() => {
    const fetchEducationList = async () => {
      try {
        const response = await instance.get(
          `/api/educations?${query}&locale=${currentLanguage}&populate=*`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
            },
          },
        );

        setEducationList(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchEducationList();
  }, [searchValue, rubric, currentLanguage]);

  return { educationList, loading, error };
};

export default useGetEducationList;
