import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Item from './Item';
import useGetBotsList from '../../../../hooks/fetch/bots/useGetBotsList';

const Index = ({ currentId }) => {
  const { id } = useParams();
  const { botsList, loading, error } = useGetBotsList();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!loading) {
      setData(botsList.data);
    }
  }, [loading]);

  return (
    <aside className="flex flex-col  w-full    lg:w-1/3  gap-[60px] sm:gap-10 mb-10 lg:mb-0 lg:overflow-y-scroll hide-scrollbar">
      {!loading &&
        botsList &&
        botsList.data.map((item) => {
          if (item.id != currentId)
            return <Item key={item.attributes.name + item.id} {...item.attributes} id={item.id} />;
        })}
      {/* {Array.from(new Array(12)).map((item, index) => (
        <Item
          key={index}
          id={index}
          name={'adsasd'}
          short_description={`asdasdas`}
          cover_interface={`/uploads/Cupibara2_af928fc38a.webp`}
        />
      ))} */}
    </aside>
  );
};

export default Index;
