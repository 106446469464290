import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import instance from '../instance';

const useGetFaqList = () => {
  const [faqList, setFaqList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const currentLanguage = localStorage.getItem('language');

  useEffect(() => {
    const fetchFAQList = async () => {
      try {
        const response = await instance.get(`/api/faqs?locale=${currentLanguage}`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
          },
        });
        setFaqList(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchFAQList();
  }, [currentLanguage]);

  return { faqList, loading, error };
};

export default useGetFaqList;
