import { useState, useEffect } from 'react';
import instance from '../instance';

const useGetEducationOne = (id) => {
  const [educationOne, setEducationOne] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const currentLanguage = localStorage.getItem("language");

  // let query = {};

  //If the category is not selected, then search by these categories
  //   if (rubric) {
  //     query = qs.stringify(
  //       {
  //         filters: {
  //           $and: [
  //             {
  //               rubric: {
  //                 id: {
  //                   $eq: rubric,
  //                 },
  //               },
  //             },
  //             {
  //               name: {
  //                 $containsi: searchValue,
  //               },
  //             },
  //           ],
  //         },
  //       },
  //       {
  //         encodeValuesOnly: true, // prettify URL
  //       },
  //     );
  //   } else {
  //     query = qs.stringify(
  //       {
  //         filters: {
  //           name: {
  //             $containsi: searchValue,
  //           },
  //         },
  //       },
  //       {
  //         encodeValuesOnly: true, // prettify URL
  //       },
  //     );
  //   }

  useEffect(() => {
    const fetchEducationOne = async () => {
      try {
        const response = await instance.get(
          `/api/educations/${id}?locale=${currentLanguage}&populate=*`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
            },
          },
        );

        setEducationOne(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchEducationOne();
  }, [id, currentLanguage]);

  return { educationOne, loading, error };
};

export default useGetEducationOne;
