import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import markdownToHtml from '../../helpers/markdownToHtml';

const Card = ({
  id,
  activeSlide,
  setActiveSlide,
  name,
  cover,
  cover_short,
  date,
  createdAt,
  text,
}) => {
  const [formattedText, setFormattedText] = useState('');
  const img =
    cover.data?.attributes.provider === 'local'
      ? process.env.REACT_APP_BACKEND_URL + cover_short.data?.attributes.url
      : cover_short.data?.attributes.url;

  useEffect(() => {
    handleText();
  }, []);

  const handleText = async () => {
    setFormattedText(await markdownToHtml(text));
  };
  return (
    <Link to={`/education/${id}`}>
      <article
        className={` cursor-pointer relative  ${
          activeSlide ? 'w-ful aspect-[5/5] sm:aspect-[4/5]' : 'w-full aspect-[5/5] sm:aspect-[4/5]'
        } noselect group h-fit`}>
        <div className="card flex flex-col justify-between   p-5 lg:px-3 [clip-path:polygon(0_0,80%_0,100%_16.2%,100%_110%,0_110%)] md:[clip-path:polygon(0_0,80%_0,100%_16.2%,100%_110%,0_110%)] lg:[clip-path:polygon(0_0,80%_0,100%_16.2%,100%_110%,0_110%)]">
          <div className="absolute right-[-30.3%] top-0 w-full rotate-[39deg]  sm:rotate-[45deg] border-[1px] border-green"></div>
          <div className="w-full md:w-5/6 lg:w-4/5 ">
            <p className="font-bold text-base sm:text-[20px] md:text-[18px]">{name}</p>
            <p className="mt-3 text-xs sm:text-[13px]">{moment(date).format('MMMM DD, YYYY')}</p>
          </div>

          <div className="relative  rounded  overflow-hidden w-full h-[50%] ">
            <img src={img} alt={name} className="w-full h-full object-cover" />
          </div>
        </div>
      </article>
    </Link>
  );
};

export default Card;
