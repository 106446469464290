import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Swiper from "swiper";
import SvgSprite from "../../ui/SvgSprite";
// import 'swiper/swiper-bundle.min.css';
import "swiper/css";
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';

const HorizontalSlider = () => {
    const { t } = useTranslation();

    const slidesContent = t("home.about.slides", { returnObjects: true });

    useEffect(() => {
        const swiper = new Swiper(".swiper-about-horizontal", {
            slidesPerView: 1,
            spaceBetween: 30,
            loop: true,
            // autoHeight: true, // Включение автоматической высоты слайдера
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });

        swiper.update();

        return () => {
            swiper.destroy(true, true);
        };
    }, []);

    return (
        <div className='swiper-container swiper-about-horizontal mt-8 '>
            <div className='swiper-wrapper'>
                {slidesContent.map((el, index) => (
                    <div className='swiper-slide' key={index}>
                        {el}
                    </div>
                ))}
            </div>

            <div className='flex w-1/3 mt-7 ml-auto'>
                <div className='swiper-button-prev  my-0 mx-auto w-fit rotate-[-90deg]'>
                    <SvgSprite src='/img/svg/icons/arrow_slider_blue.svg' width={28} height={36} />
                </div>
                <div className='swiper-button-next  my-0 mx-auto w-fit rotate-90'>
                    <SvgSprite src='/img/svg/icons/arrow_slider_blue.svg' width={28} height={36} />
                </div>
            </div>
        </div>
    );
};

export default HorizontalSlider;
