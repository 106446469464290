import "./style.scss";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import i18n from "./locales/i18n";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/ru";

import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import Home from "./pages/Home";
import FAQ from "./pages/FAQ";
import Trading from "./pages/Trading";
import TradingSinglePage from "./pages/TradingSinglePage";
import Education from "./pages/Education";

import NotFound from "./pages/NotFound";
import Success from "./pages/Success";
function App() {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    useEffect(() => {
        const savedLanguage = localStorage.getItem("language");

        if (!savedLanguage) localStorage.setItem("language", "en");
        i18n.changeLanguage(savedLanguage);
    }, [currentLanguage]);

    return (
        <div className='relative App'>
            <div className="z-[-1] pointer-events-none absolute top-0 left-0 w-full h-full bg-net opacity-10 bg-[url('/public/img/bg_net.svg')]"></div>
            <Header />
            <main>
                <Routes>
                    <Route path='/' exact element={<Home />} />
                    <Route path='/faq' element={<FAQ />} />
                    <Route path='/trading' element={<Trading />} />
                    <Route path='/trading/:id' element={<TradingSinglePage />} />
                    <Route path='/education' element={<Education />} />
                    <Route path='/education/:id' element={<Education />} />
                    <Route path='/success/:name' element={<Success />} />
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </main>

            <Footer />
        </div>
    );
}

export default App;
