import React, { useEffect, useState } from 'react';
import Item from './Item';
import SubMin from '../../ui/Subscription/SubMin';
import { useTranslation } from 'react-i18next';
import useGetBotsList from '../../../hooks/fetch/bots/useGetBotsList';

const Index = () => {
  const { t } = useTranslation();
  const { botsList, loading, error } = useGetBotsList();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!loading) {
      setData(botsList.data);
    }
  }, [loading, botsList]);
  return (
    <section className="relative pt-[100px] sm:pt-[140px] pb-[50px] sm:pb-0 ">
      {/* <div className="z-[-1] pointer-events-none absolute top-0 left-0 w-full h-full bg-snippet bg-net opacity-10 bg-[url('/public/img/bg_net.svg')]"></div> */}
      <div className="container">
        <h3 className="w-3/5 sm:w-full lg:w-3/5">{t('trading.title_list')}</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-5 mt-[20px] sm:mt-[60px]">
          {!loading && data && data.length
            ? data.map((item) => (
                <Item key={item.attributes.name + item.id} id={item.id} {...item.attributes} />
              ))
            : ''}
        </div>
      </div>
      <div className="w-full mt-[90px]">
        <SubMin
          title={t('home.hero.subscribe.title')}
          placeholder={t('home.hero.subscribe.placeholder')}
          theme={`green`}
        />
      </div>
    </section>
  );
  return (
    <section className="relative pt-[200px] pb-[50px] sm:pb-0 ">
      <div className="z-[-1] pointer-events-none absolute top-0 left-0 w-full h-full bg-snippet bg-net opacity-10 bg-[url('/public/img/bg_net.svg')]"></div>
      <div className="container">
        <h3 className="w-3/5">{t('trading.title_list')}</h3>
        <div className=" flex flex-col sm:flex-row flex-wrap justify-between  gap-5 lg:gap-2 mt-[60px]">
          {!loading && data && data.length
            ? data.map((item) => <Item key={item.attributes.name + item.id} {...item.attributes} />)
            : ''}
        </div>
      </div>
      <div className="w-full mt-[90px]">
        <SubMin
          title={`Stay up to dates on all thing at 1ex `}
          palceholder={`YOUR EMAIL`}
          theme={`blue`}
        />
      </div>
    </section>
  );
};

export default Index;
