import React, { useEffect, useRef } from "react";
import Swiper from "swiper";
import SvgSprite from "../../ui/SvgSprite";
import useScreenSize from "../../../hooks/useScreenSize";
import { useTranslation } from "react-i18next";

const VerticalSlider = () => {
    const screenSize = useScreenSize();
    const { t } = useTranslation();
    const swiperRef = useRef(null);

    const slidesContent = t("home.about.slides", { returnObjects: true });
    const duplicatedSlides = slidesContent.concat(slidesContent);

    useEffect(() => {
        if (swiperRef.current) {
            const swiper = new Swiper(swiperRef.current, {
                slidesPerView: 3,
                spaceBetween: 10,
                loop: true,
                direction: "vertical",
                autoHeight: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            });

            swiper.update();

            return () => {
                swiper.destroy(true, true);
            };
        }
    }, []);

    useEffect(() => {
        if (swiperRef.current) {
            const loopSlides = () => {
                const slides = swiperRef.current.querySelectorAll(".swiper-slide");
                slides.forEach((slide, index) => {
                    const newIndex = (swiperRef.current.activeIndex + index) % slidesContent.length;
                    slide.innerHTML = duplicatedSlides[newIndex];
                });
            };

            swiperRef.current.addEventListener("slideChange", loopSlides);
        }
    }, [duplicatedSlides]);

    return (
        <div className='flex flex-col flex-1'>
            <div className='swiper-button-prev my-0 mx-auto w-fit'>
                <SvgSprite src='/img/svg/icons/arrow_slider_blue.svg' width={46} height={46} />
            </div>
            <div className='swiper-container swiper-about-vertical' ref={swiperRef}>
                <div className='swiper-wrapper'>
                    {duplicatedSlides.map((content, index) => (
                        <div className='swiper-slide' key={index}>
                            <div className='text-base text-justify leading-[19.2px]'>{content}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='swiper-button-next my-0 mx-auto w-fit rotate-180'>
                <SvgSprite src='/img/svg/icons/arrow_slider_blue.svg' width={46} height={46} />
            </div>
        </div>
    );
};

export default VerticalSlider;
