import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../ui/Button';

const Item = ({ id, name, short_description, link, cover_interface }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const img =
    cover_interface?.data?.attributes.provider === 'local'
      ? process.env.REACT_APP_BACKEND_URL + cover_interface.data?.attributes.url
      : cover_interface.data?.attributes.url;
  return (
    <article className="flex flex-col w-full  p-5 lg:p-7 bg-black-200 rounded">
      <div className="w-full rounded overflow-hidden h-[200px] sm:h-[230px] lg:h-[290px]">
        <img src={img} alt={name} loading="lazy" className="object-cover w-full h-full" />
      </div>

      <p className=" text-[22px]  sm:text-[26px] lg:text-base xl:text-[30px] font-bold mt-10">
        {name}
      </p>
      <p className="font-cera text-xs sm:text-base  mt-5 pb-5">{short_description}</p>
      <div className="relative flex flex-col gap-[10px] mt-auto">
        <a href={link} download>
          <Button
            value={t('trading.card_buttons.download')}
            textSize={`text-base sm:text-[18px]`}
            width={`w-full`}
          />
        </a>

        <Button
          value={t('trading.card_buttons.more')}
          textColor={`text-white`}
          bgColor={`bg-black-200`}
          textSize={`text-base sm:text-[18px]`}
          callback={() => {
            navigate(`/trading/${id}`);
          }}
        />
      </div>
    </article>
  );
  return (
    <article className="w-full sm:w-[48%] lg:w-[32.6%] p-5 lg:p-7 bg-black-200 rounded">
      <div className="w-full rounded overflow-hidden">
        <img src={img} alt="robots" className="object-cover w-full" />
      </div>

      <p className=" text-xl font-bold mt-10">{name}</p>
      <p className="text-base mt-5">{short_description}</p>
      <div className="relative flex flex-col gap-[10px] mt-10">
        <a href={link} download>
          <Button value={`Download`} textSize={`text-base sm:text-[18px]`} width={`w-full`} />
        </a>

        <Button
          value={`More info`}
          textColor={`text-white`}
          bgColor={`bg-black-200`}
          textSize={`text-base sm:text-[18px]`}
          callback={() => {
            navigate('/trading/1');
          }}
        />
      </div>
    </article>
  );
};

export default Item;
