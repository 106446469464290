import React, { useEffect } from 'react';
import Hero from '../components/FAQ/Hero';
import { Helmet } from 'react-helmet';

const FAQ = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>FAQ</title>
        <meta
          name="description"
          content="Have questions? Find answers in our FAQ section. We've compiled a list of frequently asked questions to provide you with quick and helpful information. If your question isn't listed, feel free to reach out to us."
        />
        <meta property="og:title" content="FAQ" />
        <meta
          property="og:description"
          content="Have questions? Find answers in our FAQ section. We've compiled a list of frequently asked questions to provide you with quick and helpful information. If your question isn't listed, feel free to reach out to us."
        />
        <meta property="og:url" content={`${process.env.REACT_APP_DOMAIN}/faq`} />
      </Helmet>
      <Hero />
    </>
  );
};

export default FAQ;
