import React, { useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import SvgSprite from '../ui/SvgSprite';
import debounce from 'lodash.debounce';

const Searcher = ({ searchValue, setSearchValue }) => {
  const { t } = useTranslation();
  const inputSearch = useRef();
  const [searchLocal, setSearchLocal] = useState('');

  const onChangeInputSearch = (value) => {
    setSearchLocal(value);
    updateInputSearch(value);
  };

  const updateInputSearch = useCallback(
    debounce((value) => {
      setSearchValue(value);
    }, 800),
    [],
  );

  return (
    <div className="flex w-full   rounded">
      <div className="flex justify-between lg:justify-normal w-full h-full  text-white">
        <label className="relative w-full h-full">
          <input
            ref={inputSearch}
            value={searchLocal}
            className={`rounded  py-4 px-4   pr-10 md:min-w-[330px] w-full h-full font-roboto border-2 border-blue bg-black-200 placeholder-white text-base   `}
            type="email"
            name="email"
            placeholder={t('education.placeholder')}
            onChange={(e) => {
              onChangeInputSearch(e.target.value);
            }}
          />
          <div className="cursor-pointer absolute right-2 top-2/4 translate-y-[-50%] flex items-center justify-center w-7 h-7 border-2 border-white rounded-full">
            <SvgSprite src="/img/svg/icons/arrow_small.svg" width={15} height={15} />
          </div>
        </label>
      </div>
    </div>
  );
};

export default Searcher;
