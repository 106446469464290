import React from 'react';
import SvgSprite from '../../../ui/SvgSprite';

const Option = ({ active, title }) => {
  return (
    <li className="flex items-center justify-start  gap-[15px] font-cera text-xs sm:text-base xxl:text-[18px]">
      <div className="">
        {active ? (
          <SvgSprite src="/img/svg/pricing_active.svg" width={22} height={22} />
        ) : (
          <SvgSprite src="/img/svg/pricing_disable.svg" width={22} height={22} />
        )}
      </div>
      {title}
    </li>
  );
};

export default Option;
