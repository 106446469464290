import { useState, useEffect } from 'react';
import instance from '../instance';

const useGetBotsOne = (id) => {
  const [bot, setBot] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const currentLanguage = localStorage.getItem("language");

  useEffect(() => {
    const fetchBotOne = async () => {
      try {
        const response = await instance.get(
          `/api/botss/${id}?populate=*&locale=${currentLanguage}`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
            },
          },
        );
        setBot(response.data.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBotOne();
  }, [id, currentLanguage]);

  return { bot, loading, error };
};

export default useGetBotsOne;
