import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import createRecordList from '../../../services/sendGrid/createRecordList';
import Button from '../../ui/Button';
import SvgSprite from '../../ui/SvgSprite';

const MailForm = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState('');
  const [subject, setSubject] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [checkbox, setCheckbox] = useState(true);
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  const validateEmail = (email) => {
    // Регулярное выражение для проверки валидности email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    // Обновление состояния полей формы
    if (id === 'companyName') {
      setCompanyName(value);
    } else if (id === 'subject') {
      setSubject(value);
    } else if (id === 'email') {
      setEmail(value);
    } else if (id === 'message') {
      setMessage(value);
    } else if (id === 'subscribe') {
      setCheckbox((prevCheckbox) => !prevCheckbox);
    }
  };

  const validateForm = () => {
    const errors = {};
    let formIsValid = true;

    // Проверка поля Company Name
    if (!companyName.trim()) {
      errors.companyName = `${t('footer.form.name')} ${
        currentLanguage == 'en' ? 'is required' : ' - поле обязательное к заполнению'
      } `;
      formIsValid = false;
    }

    // Проверка поля Subject
    if (!subject.trim()) {
      errors.subject = `${t('footer.form.subject')} ${
        currentLanguage == 'en' ? 'is required' : ' - поле обязательное к заполнению'
      } `;
      formIsValid = false;
    }

    // Проверка поля Email
    if (!email.trim()) {
      errors.email = `${t('footer.form.email')} ${
        currentLanguage == 'en' ? 'is required' : ' - поле обязательное к заполнению'
      } `;
      formIsValid = false;
    } else if (!validateEmail(email)) {
      errors.email =
        currentLanguage == 'en'
          ? `Invalid ${t('footer.form.email')} format`
          : `Неверный формат ${t('footer.form.email')}`;
      formIsValid = false;
    }

    // Установка ошибок и состояния валидности формы
    setErrors(errors);
    setIsFormValid(formIsValid);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateForm();

    if (isFormValid) {
      const sendMail = async () => {
        const msg = {
          message: {
            recipients: [
              {
                email: 'info@1ex.com',
              },
            ],
            global_metadata: checkbox && {
              campaign_id: '6d31f976-bb63-11ee-8e0b-4ed15405c1c7',
            },
            body: {
              html: `<p>This message was sent from algo.1ex.com!</p><p>Email: ${email}<br>Company Name: ${companyName} <br>Message: ${message}</p>`,
            },
            subject: 'Send from algo.1ex.com',
            from_email: 'info@1ex.com',
          },
        };

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/sender/mail`, {
          method: 'POST',
          headers: {
            'Content-Type': `application/json`,
            Accept: `application/json`,
            Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
          },
          body: JSON.stringify(msg),
        });

        const newData = {
          email: email.trim(),
          form_name: 'callback',
        };

        if (!response.ok) {
          throw new Error('The email has not been sent');
        } else {
          navigate('/success/mail');
        }
      };
      sendMail();
    } else {
      console.log('Form has errors. Cannot send message.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-5 h-full text-black">
      <label htmlFor="companyName">
        <input
          id="companyName"
          className={`w-full bg-white rounded py-[18px] px-11 font-cera ${
            errors.companyName ? 'border-2 border-blue ' : ''
          } `}
          type="text"
          placeholder={t('footer.form.name')}
          value={companyName}
          onChange={handleInputChange}
          onBlur={validateForm}
        />
        {errors.companyName && (
          <span className="block text-blue text-right animate-fadeTopBottom ">
            {errors.companyName}
          </span>
        )}
      </label>
      <label htmlFor="subject">
        <input
          id="subject"
          className={`w-full bg-white rounded py-[18px] px-11 font-cera ${
            errors.subject ? 'border-2 border-blue ' : ''
          } `}
          type="text"
          placeholder={t('footer.form.subject')}
          value={subject}
          onChange={handleInputChange}
          onBlur={validateForm}
        />
        {errors.subject && (
          <span className="block text-blue text-right animate-fadeTopBottom">{errors.subject}</span>
        )}
      </label>
      <label htmlFor="email">
        <input
          id="email"
          className={`w-full bg-white rounded py-[18px] px-11 font-cera ${
            errors.email ? 'border-2 border-blue ' : ''
          } `}
          type="email"
          placeholder={t('footer.form.email')}
          value={email}
          onChange={handleInputChange}
          onBlur={validateForm}
        />
        {errors.email && (
          <span className="block text-blue text-right animate-fadeTopBottom">{errors.email}</span>
        )}
      </label>

      <textarea
        id="message"
        className={`w-full min-h-[200px]  bg-white rounded py-[18px] px-11 grow font-cera `}
        placeholder={t('footer.form.message')}
        value={message}
        onChange={handleInputChange}
      />
      <div className="flex  ">
        <input
          id="subscribe"
          className={`form-subscribe-checkbox`}
          type="checkbox"
          checked={checkbox}
          onChange={handleInputChange}
        />
        <label
          htmlFor="subscribe"
          className="form-subscribe-label font-cera text-white text-xs sm:text-base md:text-[15px] xxl:text-[18px] select-none">
          {t('footer.form.subscribe')}
        </label>
      </div>

      <Button
        type="submit"
        value={t('footer.form.button').toUpperCase()}
        icon={<SvgSprite src="/img/svg/icons/arrow.svg" width={27} height={20} />}
        align={`justify-between`}
        disabled={!isFormValid}
      />
    </form>
  );
};

export default MailForm;
