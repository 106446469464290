import React from 'react';

const ItemMenuMobile = ({ id, title, activeSlideIndex, setActiveSlideIndex }) => {
  return (
    <li
      className={`cursor-pointer px-[15px] pb-[24px]  sm:px-[25px] whitespace-nowrap ${
        activeSlideIndex == id ? 'text-green active' : ''
      }  trading-item`}
      onClick={() => {
        setActiveSlideIndex(id);
      }}>
      {title}
    </li>
  );
};

export default ItemMenuMobile;
