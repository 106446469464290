import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Item from './Item';
import SkeletonCard from './SkeletonCard';

import SvgSprite from '../../../ui/SvgSprite';
import { register } from 'swiper/element/bundle';
// register Swiper custom elements

import useGetEducationList from '../../../../hooks/fetch/education/useGetEducationList';

const Index = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const swiperElRef = useRef();
  const { educationList, loading, error } = useGetEducationList();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!loading) {
      register();
      // initialize swiper
      swiperElRef.current.initialize();
      setData(educationList.data);
    }
  }, [loading, educationList, currentLanguage]);

  useEffect(() => {}, []);

  useEffect(() => {
    // Object with parameters
    const params = {
      breakpoints: {
        100: {
          slidesPerView: 'auto',
          spaceBetween: 10,
        },
        576: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        1440: {
          slidesPerView: 4,
        },
      },
      loop: true,
    };

    // Assign it to swiper element
    Object.assign(swiperElRef.current, params);
  }, [window.innerWidth]);

  return (
    <>
      <div className="slider-education overflow-hidden ">
        <swiper-container
          ref={swiperElRef}
          //  slides-per-view="4"
          space-between="20"
          speed="500"
          loop="true">
          {!loading &&
            data &&
            data.length &&
            data.map((item) => (
              <Item key={item.attributes.createdAt + item.id} id={item.id} {...item.attributes} />
            ))}
        </swiper-container>
      </div>

      <div className="flex justify-end mt-7">
        <div
          className="cursor-pointer rotate-180 mr-[90px]"
          onClick={() => {
            swiperElRef.current.swiper.slidePrev();
          }}>
          <SvgSprite src="/img/svg/icons/arrow_slider.svg" width={17} height={36} />
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            swiperElRef.current.swiper.slideNext();
          }}>
          <SvgSprite src="/img/svg/icons/arrow_slider.svg" width={17} height={36} />
        </div>
      </div>
    </>
  );
};

export default Index;
