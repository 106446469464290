import React from 'react';

const ItemMenu = ({ id, title, activeSlideIndex, setActiveSlideIndex }) => {
  return (
    <li
      className={`cursor-pointer py-4 xxl:py-[22px] ${
        activeSlideIndex == id ? 'text-green active' : ''
      }  trading-item`}
      onClick={() => {
        setActiveSlideIndex(id);
      }}>
      <div
        className={`${
          activeSlideIndex == id ? 'w-[50px] h-[2px] mr-5' : 'w-[0px] h-[0px]'
        } bg-green my-auto  inline-block align-middle duration-200`}></div>
      {title}
    </li>
  );
  //   return (
  //     <li
  //       className={`cursor-pointer py-[22px] ${
  //         activeSlideIndex == id ? 'text-green active' : ''
  //       }  trading-item`}
  //       onClick={() => {
  //         setActiveSlideIndex(id);
  //       }}>
  //       {activeSlideIndex == id && (
  //         <div className=" w-[50px] h-[2px] bg-green my-auto mr-5 inline-block align-middle"></div>
  //       )}
  //       {title}
  //     </li>
  //   );
};

export default ItemMenu;
