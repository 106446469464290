import React from "react";

const Video = ({ id }) => {
    return (
        <iframe
            width='100%'
            height='100%'
            src={`https://www.youtube.com/embed/${id}`}
            title='YouTube video player'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen='allowfullscreen'
        ></iframe>
    );
};

export default Video;
