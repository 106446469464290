import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../ui/Button';
import SvgSprite from '../../ui/SvgSprite';
import Switcher from '../../ui/Switcher';

import PriceCard from './PriceCard';
import { Link } from 'react-router-dom';

const Index = () => {
  const { t } = useTranslation();
  const [annually, setAnnually] = useState(false);

  const pricesData = [
    {
      title: t('home.pricing.price_cards.first.title'),
      desc: t('home.pricing.price_cards.first.description'),
      options: [
        {
          title: t('home.pricing.price_cards.first.options.first'),
          active: true,
        },
        {
          title: t('home.pricing.price_cards.first.options.second'),
          active: true,
        },
        ,
        {
          title: t('home.pricing.price_cards.first.options.third'),
          active: true,
        },
        {
          title: t('home.pricing.price_cards.first.options.fourth'),
          active: true,
        },
        {
          title: t('home.pricing.price_cards.first.options.fifth'),
          active: false,
        },
        {
          title: t('home.pricing.price_cards.first.options.sixth'),
          active: false,
        },
        {
          title: t('home.pricing.price_cards.first.options.seventh'),
          active: false,
        },
        {
          title: t('home.pricing.price_cards.first.options.eight'),
          active: false,
        },
        {
          title: t('home.pricing.price_cards.first.options.ninth'),
          active: false,
        },
      ],
      prices: {
        month: 30,
        annually: 250,
      },
      popular: false,
    },
    {
      title: t('home.pricing.price_cards.second.title'),
      desc: t('home.pricing.price_cards.second.description'),
      options: [
        {
          title: t('home.pricing.price_cards.second.options.first'),
          active: true,
        },
        {
          title: t('home.pricing.price_cards.second.options.second'),
          active: true,
        },
        ,
        {
          title: t('home.pricing.price_cards.second.options.third'),
          active: true,
        },
        {
          title: t('home.pricing.price_cards.second.options.fourth'),
          active: true,
        },
        {
          title: t('home.pricing.price_cards.second.options.fifth'),
          active: true,
        },
        {
          title: t('home.pricing.price_cards.second.options.sixth'),
          active: true,
        },
        {
          title: t('home.pricing.price_cards.second.options.seventh'),
          active: true,
        },
        {
          title: t('home.pricing.price_cards.second.options.eight'),
          active: true,
        },
        {
          title: t('home.pricing.price_cards.second.options.ninth'),
          active: true,
        },
        {
          title: t('home.pricing.price_cards.second.options.tenth'),
          active: true,
        },
        {
          title: t('home.pricing.price_cards.second.options.eleventh'),
          active: false,
        },
      ],
      prices: {
        month: 50,
        annually: 420,
      },
      popular: true,
    },
    {
      title: t('home.pricing.price_cards.third.title'),
      desc: t('home.pricing.price_cards.third.description'),
      options: [
        {
          title: t('home.pricing.price_cards.third.options.first'),
          active: true,
        },
        {
          title: t('home.pricing.price_cards.third.options.second'),
          active: true,
        },
        ,
        {
          title: t('home.pricing.price_cards.third.options.third'),
          active: true,
        },
        {
          title: t('home.pricing.price_cards.third.options.fourth'),
          active: true,
        },
        {
          title: t('home.pricing.price_cards.third.options.fifth'),
          active: true,
        },
        {
          title: t('home.pricing.price_cards.third.options.sixth'),
          active: true,
        },
        {
          title: t('home.pricing.price_cards.third.options.seventh'),
          active: true,
        },
        {
          title: t('home.pricing.price_cards.third.options.eight'),
          active: true,
        },
        {
          title: t('home.pricing.price_cards.third.options.ninth'),
          active: true,
        },
        {
          title: t('home.pricing.price_cards.third.options.tenth'),
          active: true,
        },
        {
          title: t('home.pricing.price_cards.third.options.eleventh'),
          active: true,
        },
      ],
      prices: {
        month: 200,
        annually: 1680,
      },
      popular: false,
    },
  ];

  return (
    <section className="relative  py-[50px] sm:py-[90px]   " /*sm:min-h-[1473px]*/ id="pricing">
      {/* <div className="z-[-1] pointer-events-none absolute top-0 left-0 w-full h-full bg-snippet bg-net opacity-10 bg-[url('/public/img/bg_net.svg')]"></div> */}
      <div className="container grid grid-cols-4   sm:gap-5">
        <h2 className="col-span-4 sm:col-span-2 xl:col-span-4 sm:order-1 ">
          {t('home.pricing.title')}
        </h2>
        <p className="col-span-4  sm:col-span-2 sm:order-2 uppercase text-base sm:text-[20px] lg:text-[26px]  xl:text-[30px]  w-full sm:text-right xl:text-left mt-5 sm:mt-0 ">
          {t('home.pricing.description')}
        </p>
        <div className="col-span-4 sm:col-span-2 order-4 xl:order-3  sm:flex sm:justify-between sm:flex-col">
          <div className="  flex w-full  justify-end mt-7 sm:mt-0">
            <Switcher
              values={[t('home.pricing.switcher.monthly'), t('home.pricing.switcher.annually')]}
              width={`w-full sm:w-auto lg:w-[80%] xl:w-auto`}
              align={`justify-center`}
              callback={setAnnually}
            />
          </div>
        </div>
        <div className="col-span-4 sm:col-span-2  order-3 xl:order-4 mt-7 sm:mt-0">
          <Link to="https://account.1ex.com/ru/auth/signup">
            <Button
              value={t('home.pricing.button')}
              icon={<SvgSprite src="/img/svg/icons/arrow.svg" width={27} height={20} />}
              align={`justify-between`}
              width={`w-full sm:w-4/5 lg:w-[67%] xl:w-[47%]`}
              textSize={`text-base lg:text-[18px]`}
            />
          </Link>

          <p className="mt-5 text-xs sm:text-sm lg:text-[20px] font-bold">
            {t('home.pricing.under_button')}
          </p>
        </div>

        <div className=" col-span-4 order-5 flex items-end justify-center gap-7 sm:gap-8 lg:gap-7 mt-7 lg:mt-24 flex-col lg:flex-row ">
          {pricesData.map((item) => (
            <PriceCard key={item.title + item.desc} {...item} annually={annually} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Index;
