import { useState, useEffect } from 'react';
import instance from '../instance';

const useGetRubricList = () => {
  const [rubricList, setRubricList] = useState([]);
  const [loadingRubric, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const currentLanguage = localStorage.getItem("language");

  useEffect(() => {
    const fetchRubricList = async () => {
      try {
        const response = await instance.get(`/api/rubrics?populate=*&locale=${currentLanguage}`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
          },
        });
        setRubricList(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchRubricList();
  }, [currentLanguage]);

  return { rubricList, loadingRubric, error };
};

export default useGetRubricList;
