import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../components/ui/Button';
import SvgSprite from '../components/ui/SvgSprite';

const NotFound = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Algo</title>
        <meta
          name="description"
          content="Welcome to Algo - Your Gateway to Algorithmic Trading Excellence. Discover cutting-edge solutions and elevate your trading experience with us."
        />
        <meta property="og:title" content="Algo" />
        <meta
          property="og:description"
          content="Welcome to Algo - Your Gateway to Algorithmic Trading Excellence. Discover cutting-edge solutions and elevate your trading experience with us."
        />
        <meta property="og:url" content={process.env.REACT_APP_DOMAIN} />
      </Helmet>
      <section className="relative bg-offset-x-60  bg-snippet bg-conic-blue bg-[url('/public/img/hero_bg_gradient.webp')]">
        <div className="container flex flex-col min-h-screen justify-center items-center ">
          <div className="text-[129px] sm:text-[180px] lg:text-[225px] font-bold ">
            {t('not_found.title')}
          </div>
          <p className="  text-center text-base sm:text-lg lg:text-2xl w-[250px] sm:w-[350px] md:w-[400px]  lg:w-[500px]">
            {t('not_found.description')}
          </p>
          <Link to="/" className=" mt-[70px]">
            <Button
              value={t('not_found.button')}
              icon={<SvgSprite src="/img/svg/icons/arrow.svg" width={27} height={20} />}
            />
          </Link>
        </div>
      </section>
    </>
  );
};

export default NotFound;
